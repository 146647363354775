import React, {FC} from 'react';
import ApiClient from '../../../modules/api-client/ApiClient';
import {GlazingBarPicker} from '../../../backoffice/modules/pim/glazingbar/GlazingBarPicker';
import {GlazingProfilePicker} from '../../../backoffice/modules/pim/glazingprofile/GlazingProfilePicker';
import {PrefValueRow} from './PrefValueRow';

export const CustomerPreferencesVastglasBinnen: FC = () => {
	const glazingBarQuery = () => ApiClient.Preferences.Preferences.getGlazingBars().then((res) => res.data);

	const glazingProfileQuery = () => ApiClient.Preferences.Preferences.getGlazingProfiles().then((res) => res.data);

	return (
		<>
			<PrefValueRow
				label={'Standaard glaslat'}
				field={{
					name: 'glazingBarId',
					autoFocus: true,
					component: GlazingBarPicker({
						required: false,
						multi: false,
						queryFn: glazingBarQuery,
					}),
				}}
			/>

			<PrefValueRow
				label={'Standaard beglazingsprofiel'}
				field={{
					name: 'glazingProfileId',
					component: GlazingProfilePicker({
						required: false,
						multi: false,
						queryFn: glazingProfileQuery,
					}),
				}}
			/>
		</>
	);
};
