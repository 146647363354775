import ApiClient from '../../../../../modules/api-client/ApiClient';
import {toast} from "react-hot-toast";
import {useEkosietMutation} from "../../../../../shared/hooks/useEkosietMutation";
import {NotFound, ValidationFailed} from "../../../../../modules/api-client/Responses";

export const usePostProofOfDelivery = () => {

    return useEkosietMutation<void, NotFound | ValidationFailed, ProofOfDeliveryParams>((params) => {
            return ApiClient.Em.Expedition.addProofOfDelivery(params.orderIds, params.signatureFileIds, params.photoFileIds).then();
        },
        {
            onSuccess: (data, variables) => {
                toast.success("Handtekening en foto succesvol opgeslagen");
            },
            onError: () => {
                toast.error("Foto en/of handtekening kon niet worden opgeslagen");
            }
        }
    );
};

type ProofOfDeliveryParams  = {
    orderIds: string[];
    signatureFileIds: string[];
    photoFileIds: string[];
}