import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {WebcamStream} from "./WebcamStream";
import {useFileUploadMutation} from "../../../../../shared/components/file-upload/useFileUploadMutation";
import {FormValues} from "../../../../../shared/components/file-upload/FileModal";
import {Formik, FormikHelpers, useFormikContext} from "formik";
import * as api from "../../../../../modules/api-client/generated";
import {ValidationErrors} from "../../../../../modules/api-client/Responses";
import {Button} from "react-bootstrap";
import SubmitButton from "../../../../../shared/components/SubmitButton";

interface PhotoCaptureProps {
    altText: string,
    description: string,
    fileCategory: api.FileCategory,
    title: string,
    saveFileId: (id: string) => void;
}

const FACING_MODE_USER = { facingMode: { exact: 'user' } }; // Front Camera
const FACING_MODE_ENVIRONMENT = { facingMode: { exact: 'environment' } }; // Back Camera
const PhotoCapture: React.FC<PhotoCaptureProps> = (props) => {
    const webcamRef = useRef<WebcamStream>(null);
    const [photoImage, setPhotoImage] = useState<string | null>(null);
    const {mutate, serverValidationErrors, data: fileId} = useFileUploadMutation();
    const [file, setFile] = useState<File | null>(null);

    const [videoConstraints, setVideoConstraints] = useState(FACING_MODE_USER);

    const switchCamera = async () => {
        const videoInputs = await navigator.mediaDevices.enumerateDevices().then(devices => devices.filter(device => device.kind === 'videoinput'));
        if (videoInputs.length > 1) {
            setVideoConstraints(prev => JSON.stringify(prev) === JSON.stringify(FACING_MODE_USER) ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER);
        } else {
            alert('Apparaat heeft maar 1 camera.');
        }
    };

    const capture = React.useCallback(() => {
        webcamRef.current?.captureImage();
    }, []);

    const handleCapture = (imageSrc: string) => {
        setPhotoImage(imageSrc);
        setFile(base64ToJpeg(imageSrc, 'deliveryPhoto.jpg'));
        webcamRef.current?.turnOffWebcam();
    };

    function base64ToJpeg(base64: string, fileName: string): File {
        const byteString = atob(base64.split(',')[1]);
        const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });
        return new File([blob], fileName, { type: mimeString });
    }

    const retake = () => {
        setPhotoImage(null);
        setFile(null);
    }

    const onSubmit = async (values: FormValues, {setSubmitting}: FormikHelpers<FormValues>) => {
        try {
            return await new Promise<void>((resolve, reject) => {
                mutate(values, {
                    onSuccess: () => {
                        resolve();
                    },
                    onError: (error) => {
                        reject();
                    },
                });
            });
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        if (fileId) {
            props.saveFileId(fileId);
        }
    }, [fileId]);

    const initialValues : FormValues = {
        file: file!,
        fileCategory: props.fileCategory,
        altText: props.altText,
        title: props.title,
        description: props.description,
    }

    return (
        <>
            {fileId ? (
                <>Foto sucesvol opgeslagen</>
            ) : (
                <>
                    <h3>Neem een foto van de afgeleverde goederen</h3>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-6 mb-3 mb-md-0 d-flex flex-column">
                                {photoImage ? (
                                    <img src={photoImage} alt="Captured"/>
                                ) : (
                                    <WebcamStream ref={webcamRef} id={"camera"} title={"camera"}
                                                  onCapture={handleCapture}/>
                                )}
                            </div>
                        </div>
                    </div>
                    <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
                        <InsertPhotosForm base64={photoImage}
                                          file={file!}
                                          handleDismiss={retake}
                                          switchCamera={switchCamera}
                                          capture={capture}
                                          serverValidationErrors={serverValidationErrors}
                                          show={true}
                                          uploadType={"image"}/>
                    </Formik>
                </>
            )}
        </>
    )
}

export {PhotoCapture};

interface InsertPhotosFormProps {
    base64: string | null;
    handleDismiss: () => void;
    switchCamera: () => void;
    capture: () => void;
    serverValidationErrors: ValidationErrors | null;
    show: boolean;
    file: File;
    uploadType: 'image' | 'other';
}

export const InsertPhotosForm: React.FC<InsertPhotosFormProps> = (props) => {
    const {handleSubmit, isSubmitting, errors, setErrors} = useFormikContext<FormValues>();
    const mergeServerErrors = () => {
        if (props.serverValidationErrors) {
            setErrors({...errors, ...props.serverValidationErrors});
        }
    };
    useLayoutEffect(mergeServerErrors, [props.serverValidationErrors, errors, setErrors]);

    return (
        <form onSubmit={handleSubmit}>
            <div className="row pt-5">
                <div className="offset-4 col-8 d-flex justify-content-end">
                    {props.base64 ? (
                        <>
                            <Button variant="link" className="mx-4" onClick={props.handleDismiss}>
                                Verwijder foto
                            </Button>
                            <SubmitButton type="submit" className="btn btn-primary" isSubmitting={isSubmitting}>
                                Opslaan
                            </SubmitButton>
                        </>
                    ) : (
                        <>
                            <Button className="btn btn-secondary me-2 mb-2" onClick={props.switchCamera}>
                                Wijzig camera
                            </Button>
                            <Button className="btn btn-primary me-2 mb-2" onClick={props.capture}>
                                Neem foto
                            </Button>
                        </>
                    )}
                </div>
            </div>
        </form>
    );
};