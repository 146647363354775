import React, {useState} from 'react';
import {
    WeeklySalesSillStatsRow,
} from '../../../../modules/api-client/generated';
import {StatsLineChartOptions} from "../../../../shared/components/Chart";
import GetYearColor from "../../../../shared/components/GetYearColor";
import {Measure, Mode} from "../../mgt/pages/MgtSillStats";
import {Line} from "react-chartjs-2";
import {isFutureDate} from "../../../../shared/components/date";
import {Chart} from "chart.js";
import {calculateTrendline} from "../../../../shared/utils/trendline";


type Props = {
    measure?: Measure
    mode?: Mode
    data: {
        [key: string]: { [key: string]: WeeklySalesSillStatsRow; };
    }
}
export const SillStatsTile: React.FC<Props> = ({measure = 'dorpels', mode = "ytd", data}) => {

    const [currentMode, setCurrentMode] = useState<Mode>(mode as Mode);

    const weekNumbers = Array.from({length: 52}, (_, i) => i + 1);

    const dataSets: any = [];
    Object.keys(data).map((year) => {
        const dataSet = {
            tension: 0.3,
            label: year,
            borderColor: '#fff',
            borderWidth: 2,
            fill: false,
            pointStyle: 'circle',
            backgroundColor: GetYearColor(year),
            pointHoverBackgroundColor: '#093869',
            pointRadius: 3,
            pointHoverRadius: 5,
            data: weekNumbers.map(week => isFutureDate(+year, +week) ? null : data[year][week]?.[measure]?.[currentMode] || 0),
        }
        dataSets.push(dataSet);
        const trendlineData = calculateTrendline(dataSet.data);
        const trendline = {
            label: dataSet.label + ' trend',
            borderColor: dataSet.backgroundColor,
            borderWidth: 1,
            data: trendlineData,
            backgroundColor: GetYearColor(dataSet.label),
            fill: false,
            pointRadius: 0,
            pointHoverRadius: 0
        }
        dataSets.push(trendline);
    });

    const graphData = {
        labels: weekNumbers,
        datasets: dataSets
    };

    const options: any = {
        ...StatsLineChartOptions, ...{
            scales: {
                x: {
                    ticks: {
                        display: true,
                        color: '#ffffff',
                    },
                },
                y: {
                    ticks: {
                        display: true,
                        color: '#ffffff',
                    }
                }
            }
        }
    }
    return (
        <div className="card mb-5 mb-xl-8">
            <div className="card-body p-0">
                <div className="px-9 py-7 card-rounded w-100 bg-success">
                    <div className="d-flex flex-stack mb-5">
                        <h3 className="m-0 text-white fw-bold fs-3">{measure}</h3>
                        <div className='form-check form-switch'>
                            <input className='form-check-input' type='checkbox' role='switch'
                                   onChange={() => setCurrentMode((currentMode === 'ytd' ? 'sum' : 'ytd') as Mode)}
                                   checked={currentMode === 'ytd'}
                                   id='toggleMode'/>
                            <label className='form-check-label text-white' htmlFor='toggleMode'>YTD</label>
                        </div>
                    </div>
                    <Line options={options} data={graphData}/>
                </div>
            </div>
        </div>
    );
};

