import React, {useState} from 'react';
import {Form, Formik, useFormikContext} from 'formik';
import {Modal} from 'react-bootstrap';
import {modalsRoot} from '../../../global-helpers';
import SpinnerButtonV2 from '../SpinnerButtonV2';
import {useConfirmDialog} from '../ConfirmDialog';

export type UsePreferenceEditModalHandle = {
	open: <TValues>(options: OpenOptions<TValues>) => void;
	component: React.ReactNode;
};

export type OpenOptions<TValues> = {
	title: string;
	value: TValues;
	formFields: () => React.ReactNode;
	save: (value: TValues) => Promise<void>;
	reset: () => Promise<void>;
};

export function usePreferenceEditModal(): UsePreferenceEditModalHandle {

	const [component, setComponent] = useState<React.ReactNode | null>(null);

	const confirm = useConfirmDialog();

	function open<TValues>(options: OpenOptions<TValues>) {
		const {save, title, value, formFields, reset} = options;

		const onSave = async (update: TValues) => {
			await save(update);
			setComponent(null);
		};

		const onReset = async () => {
			await confirm({
				title: 'Reset',
				message: 'Je staat op het punt om de voorkeuren te resetten. Weet je het zeker?',
				dialogStyle: 'danger',
				onConfirm: async () => {
					await reset();
					setComponent(null);
				},
			});
		};

		const props: PreferenceEditModalProps<TValues> = {
			title: title,
			formFields: formFields,
			value: value,
		};

		setComponent(
			<Formik initialValues={value} onSubmit={onSave} onReset={onReset}>
				{({values}) => (
					<>
						<PreferenceEditModalContent {...props} close={() => setComponent(null)} />
					</>
				)}
			</Formik>
		);
	}

	return {
		open,
		component: component,
	};
}

type PreferenceEditModalProps<TValues> = {
	title: string;
	formFields: () => React.ReactNode;
	value: TValues;
};

function PreferenceEditModalContent<TValues>(
	props: PreferenceEditModalProps<TValues> & {
		close: () => void;
	}
) {
	const formik = useFormikContext();

	const {isSubmitting, values} = formik;

	return (
		<Modal
			container={modalsRoot}
			tabIndex={-1}
			aria-hidden="true"
			autoFocus={true}
			className=""
			contentClassName={'shadow-lg'}
			show={true}
			size="lg"
			onHide={() => props.close()}
			animation={false}
			backdrop={'static'}
		>
			<Form>
				<Modal.Header closeButton>
					<Modal.Title>Voorkeuren - {props.title}</Modal.Title>
				</Modal.Header>

				<Modal.Body>{props.formFields()}</Modal.Body>

				<Modal.Footer className="d-flex justify-content-between">
					<div>
						<SpinnerButtonV2 type="reset" className="btn btn-link mx-4">
							Reset
						</SpinnerButtonV2>
					</div>
					<div>
						<SpinnerButtonV2 type="button" className="btn btn-link mx-4" onClick={props.close}>
							Annuleren
						</SpinnerButtonV2>
						<SpinnerButtonV2 type="submit" className="btn btn-primary" disabled={isSubmitting}>
							Opslaan
						</SpinnerButtonV2>
					</div>
				</Modal.Footer>
			</Form>
		</Modal>
	);
}
