import {useQuery, useQueryClient} from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {NotFound, ValidationFailed} from "../../../../modules/api-client/Responses";
import {useEkosietMutation} from "../../../../shared/hooks/useEkosietMutation";
import {CreateWallConnectionAngleCommand,UpdateWallConnectionAngleCommand,WallConnectionAngle} from "../../../../modules/api-client/generated";
import { WallConnectionAngleFormValues } from "./WallConnectionAngleUpsert";


export const useWallConnectionAngleUpsertMutation = (id?: string | undefined) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<WallConnectionAngle, NotFound | ValidationFailed, WallConnectionAngleFormValues>((values) => {
            const command: CreateWallConnectionAngleCommand | UpdateWallConnectionAngleCommand = {
                sillWidth: values.sillWidth,
                angle: values.angle
            };

            if (id) {
                return ApiClient.Pim.WallConnectionAngle.pimWallConnectionAnglesIdPut(id, undefined, command).then(response => response.data);
            }
            return ApiClient.Pim.WallConnectionAngle.pimWallConnectionAnglesPost(undefined, command).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimWallConnectionAngleIdGet', id]).then();
                queryClient.invalidateQueries(['pimWallConnectionAnglesGet']).then();
            }
        }
    );
};

export const useWallConnectionAngleQuery = (id: string | undefined) => {
    return useQuery(['pimWallConnectionAngleIdGet', id],
        () => {
            return ApiClient.Pim.WallConnectionAngle.pimWallConnectionAnglesIdGet(id!).then(res => res.data);
        }, {
            useErrorBoundary: true,
            enabled: !!id,
        });
}

export const useWallConnectionAngleDeleteMutation = (id: string) => {
    const queryClient = useQueryClient()
    return useEkosietMutation<void, NotFound | ValidationFailed, string>((id) => {
            return ApiClient.Pim.WallConnectionAngle.pimWallConnectionAnglesIdDelete(id, undefined).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimWallConnectionAngleIdGet', id]).then();
                queryClient.invalidateQueries(['pimWallConnectionAnglesGet']).then();
            }
        }
    );
};