import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Formik, FormikHelpers, useFormikContext } from 'formik';
import * as api from '../../../../modules/api-client/generated';
import { useNavigate, useParams } from 'react-router-dom';
import SubmitButton from '../../../../shared/components/SubmitButton';
import * as Yup from 'yup';
import FieldErrors from '../../../../shared/components/FieldErrors';
import Loading from '../../../../shared/components/Loading';
import { useQuery } from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import '@mdxeditor/editor/style.css'
import { FileUpload } from '../../../../shared/components/file-upload/FileUpload';
import { Button } from 'react-bootstrap';
import { useDownloadUpsertMutation } from './downloadHooks';

export interface DownloadFormValues {
    code: string;
    imageId?: string;
    downloadId?: string;
    blobUri?: string;
    thumbnailUri?: string;
    fileTitle?: string;
}

const DownloadUpsert: React.FC = (props) => {
    const { id } = useParams<{ id: string }>();
    const mutation = useDownloadUpsertMutation(id);
    const navigate = useNavigate();

    const onSubmit = async (values: DownloadFormValues, { setSubmitting }: FormikHelpers<DownloadFormValues>) => {
        await mutation.mutateAsync(values, {
            onSuccess: () => {
                navigate(`../`, { relative: 'path' });
            },
            onError: () => {
            },
            onSettled: () => {
                setSubmitting(false)
            }
        });
    };

    const { isInitialLoading, data: details, isError } = useQuery<api.DownloadDetails>(
        ['homepageDownloadUpsertGet', id],
        () => ApiClient.Homepage.getDownloadDetails(id!).then((res) => res.data),
        {
            enabled: !!id
        }); 

    if (isInitialLoading) return <Loading />;
    if (isError) return (<>Error...</>);

    const initialValues: DownloadFormValues = {
        code: details?.code ?? '',
        blobUri: details?.download?.blobUri,
        fileTitle: details?.download?.title,
        thumbnailUri: details?.image?.blobUri,
    };

    return <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={UpsertSchema}>
        <DownloadUpsertForm id={id} mutation={mutation} />
    </Formik>;
};

const UpsertSchema = Yup.object().shape({
    code: Yup.string()
        .min(1)
        .max(50)
        .required('Geen code ingevoerd'),
});

interface DownloadUpsertFormProps {
    id: string | undefined;
    mutation: ReturnType<typeof useDownloadUpsertMutation>;
}

export const DownloadUpsertForm: React.FC<DownloadUpsertFormProps> = (props) => {
    const {
        values,
        handleSubmit,
        handleChange,
        isSubmitting,
        errors,
        setFieldValue,
        setErrors,
    } = useFormikContext<DownloadFormValues>();

    const mergeServerErrors = () => {

        if (props.mutation.serverValidationErrors) {
            setErrors({ ...errors, ...props.mutation.serverValidationErrors });
        }
    };

    const navigate = useNavigate();

    useLayoutEffect(mergeServerErrors, [props.mutation.serverValidationErrors, errors, setErrors]);

    return (<form onSubmit={handleSubmit}>
        <div className={'row'}>
            <div className='col-12 col-md-10'>
                <div className='card'>
                    <div className='card-header'>
                        <h3 className='card-title'>
                            {props.id && 'Download wijzigen'}
                            {!props.id && 'Download toevoegen'}
                        </h3>
                        <div className='card-toolbar'>
                        </div>
                    </div>

                    <div className='card-body'>
                        <div className='row mb-4'>
                            <div className='col-4'>
                                <label className='form-label'>Code</label>
                                <FieldErrors field='code' />
                            </div>
                            <div className='col-8'>
                                <input type='text' className={`form-control`}
                                    name='code' required
                                    autoComplete='off'
                                    placeholder='Unieke code voor download'
                                    onChange={handleChange}
                                    value={values.code}
                                    autoFocus
                                    disabled={!!props.id}
                                />
                            </div>
                        </div>
                            <div className='row mb-4'>
                                <div className='col-4'>
                                    <label className='form-label'>Download</label>
                                    <FieldErrors field='download' />
                                </div>
                                <div className='col-8 mb-4' style={{ minHeight: "200px" }}>
                                    <div className='position-relative'>
                                        <FileUpload 
                                            fileCategory={api.FileCategory.Download}
                                            sasUri={values.blobUri ?? ''}
                                            onFileUpload={(downloadId) => setFieldValue('downloadId', downloadId)}
                                            fileTitle={values.fileTitle}
                                            show='drag'
                                            uploadType='other'
                                        />
                                    </div>
                                </div>
                                
                                {props.id &&
                                    <>
                                    <div className='col-4'>
                                        <label className='form-label'>Thumbnail</label>
                                        <FieldErrors field='image' />
                                    </div>
                                    <div className='col-8' style={{ minHeight: "200px" }}>
                                        <div className='position-relative'>
                                            <FileUpload
                                                onFileUpload={(fileId) => setFieldValue('imageId', fileId)}
                                                show='drag'
                                                fileCategory={api.FileCategory.Thumbnail}
                                                sasUri={values.thumbnailUri ?? ''}
                                                uploadType='image'
                                            />
                                        </div>
                                    </div>
                                    </>}
                            </div>
                        <div className='row pt-5'>
                            <div className='offset-4 col-8 d-flex justify-content-end'>
                                <Button onClick={() => navigate(`../`, { relative: 'path' })} className='me-5'>Annuleren</Button>
                                <SubmitButton type='submit' className='btn btn-primary'
                                    isSubmitting={isSubmitting}>Opslaan</SubmitButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>);
};

export default DownloadUpsert;