import React, {useState, useEffect} from "react";
import * as api from "../../../../../modules/api-client/generated";
import {SignatureCanvas} from "./SignatureCanvas";
import SubmitButton from "../../../../../shared/components/SubmitButton";
import OrdersWithSillSummaryOverview from "./OrdersWithSillSummaryOverview";
import {
    ExpeditionForProductionItemRepresentation,
} from "../../../../../modules/api-client/generated";
import {useQuery} from "@tanstack/react-query";
import ApiClient from "../../../../../modules/api-client/ApiClient";
import Loading from "../../../../../shared/components/Loading";
import {PhotoCapture} from './PhotoCapture';
import {usePostProofOfDelivery} from "../hooks/use-post-proof-of-delivery";

interface DeliverOrdersAddProofProps {
    orderIdToQuery: string;
    deliveredOrders: string[];
    handleClose: () => void;
}

const DeliverOrdersAddProof: React.FC<DeliverOrdersAddProofProps> = (props) => {
    const [photoFileId, setPhotoFileId] = useState<string>();
    const [signatureFileId, setSignatureFileId] = useState<string | undefined | null>(null);


    const {
        isInitialLoading,
        data: expeditionData,
        isError
    } = useQuery<ExpeditionForProductionItemRepresentation>(['emExpeditionOrderIdGet', props.orderIdToQuery], () =>
        ApiClient.Em.Expedition.emExpeditionOrderIdGet(props.orderIdToQuery).then((res) => res.data));

    const saveProofOfDeliveryMutation = usePostProofOfDelivery();
    
    useEffect(() => {
        if (photoFileId && signatureFileId) {
            saveProofOfDeliveryMutation.mutate({
                orderIds: props.deliveredOrders,
                signatureFileIds: [signatureFileId],
                photoFileIds: [photoFileId]
            });
            props.handleClose();
        }
    }, [photoFileId, signatureFileId]);

    if(isInitialLoading || !expeditionData) {
        return <Loading/>
    }

    if(isError) {
        return <div>Error...</div>
    }

    return (
        <>
            <div>De volgende orders zijn afgeleverd:</div>
            <OrdersWithSillSummaryOverview
                showHeaderLine={false}
                showCheckboxes={false}
                orderIdToQuery={props.orderIdToQuery}
                updateExpeditionStatusToDelivered={()=>{}}
                orderIdsToShow={props.deliveredOrders}
                customer={expeditionData.expedition.customer.name}
            />
            <div className="d-flex flex-column align-items-center pt-3 container">
                <SignatureCanvas
                    title={'Handtekening voor ontvangst'}
                    fileCategory={api.FileCategory.AfleverHandtekening}
                    altText={""}
                    description={""}
                    saveFileId={setSignatureFileId}
                />
                <PhotoCapture
                    altText={""}
                    description={""}
                    fileCategory={api.FileCategory.AfleverFoto}
                    saveFileId={setPhotoFileId}
                    title={""}/>
            </div>
        </>
    );
}

export default DeliverOrdersAddProof;