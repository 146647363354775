import {useQueryClient} from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import * as api from "../../../../modules/api-client/generated";
import { FileCategory } from "../../../../modules/api-client/generated";
import {NotFound, ValidationFailed} from "../../../../modules/api-client/Responses";
import {useEkosietMutation} from "../../../../shared/hooks/useEkosietMutation";
import { ActionType, ContentFormValues } from "./ContentUpsert";

export const useContentUpsertMutation = (id?: string | undefined) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<api.ContentDetails, NotFound | ValidationFailed, ContentFormValues>(async (values) => {
        if (id) {
            if (values.actionType === ActionType.ChangeMessage)
            {
                const response = await ApiClient.Homepage.changeContentMessage(id, values.message);
                return response.data;
            }
            if (values.actionType === ActionType.ChangeImage)
            {
                const response = await ApiClient.Homepage.changeImage(id, values.imageId, FileCategory.Nieuwsafbeelding);
                return response.data;
            }
            if (values.actionType === ActionType.ChangeMessageAndCreationDate)
            {
                const response = await ApiClient.Homepage.changeNewsItemMessageAndDate(id, values.message, values.datePosted?.toISOString());
                return response.data;
            }
            if (values.actionType === ActionType.ActivateNotification)
            {
                const response = await ApiClient.Homepage.activateNotification(id, values.message, values.validFrom?.toISOString(), values.validTo?.toISOString());
                return response.data;
            }
        }

        if (values.type === "image")
        {
            const response = await ApiClient.Homepage.createImage(values.code, values.imageId, FileCategory.Afbeelding);
            return response.data;
        }

        const createContent = {
            code: values.code,
            message: values.message,
            contentType: values.type as api.ContentType,
            datePosted: values.datePosted?.toISOString(),
            validFrom: values.validFrom?.toISOString(),
            validTo: values.validTo?.toISOString(),
            imageId: values.imageId,
        };

        const response = await ApiClient.Homepage.addContent(undefined, createContent);
        return response.data;
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['homepageContentDetailsGet', id]).then();
                queryClient.invalidateQueries(['homepageContentUpsertGet', id]).then();
            }
        }
    );
};

export const useContentDeleteMutation = (id: string) => {
    const queryClient = useQueryClient()
    return useEkosietMutation<void, NotFound | ValidationFailed, string>((id) => {
            return ApiClient.Homepage.deleteContent(id, undefined).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['homepageContentListGet']).then();
            }
        }
    );
};