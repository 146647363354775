import React, {FC, useId} from 'react';
import {Field, FieldAttributes, FieldProps} from 'formik';
import {HefSchuifSlidingRailTypeComponent} from '../configurator/HefSchuifRailTypeField';
import {PrefValueRow} from './PrefValueRow';

export const CustomerPreferencesHefSchuif: FC = () => {
	return (
		<>
			<PrefValueRow
				label={'Standaard glaslat'}
				field={{
					name: 'slidingRailType',
					// autoFocus: true,
					component: HefSchuifSlidingRailTypeComponent(),
				}}
			/>

			<PrefValueRow
				label={'Standaard rubberblok (LxB)'}
				field={{
					name: 'doorBarCutout',
					component: DoorBarCutoutComponent(),
				}}
			/>

			<PrefValueRow
				label={'Standaard lat vastedeur inspringen'}
				field={{
					name: 'fixedDoorBarInset',
					className: 'form-control',
					type: 'number',
					min: 0,
				}}
			/>
		</>
	);
};

export const DoorBarCutoutField: React.FC<{field: FieldAttributes<any>; disabled?: boolean}> = (props) => {
	const fieldId = useId();

	return (
		<>
			{/*<pre>{JSON.stringify(props, null, 2)}</pre>*/}
			<div className="input-group">
				<Field name={`${props.field.name}.length`}>
					{({field}: FieldProps) => <input {...field} id={`${fieldId}-length`} type="number" className="form-control" placeholder="Lengte" disabled={props.disabled} required min={1} />}
				</Field>
				<Field name={`${props.field.name}.width`}>
					{({field}: FieldProps) => <input {...field} id={`${fieldId}-width`} type="number" className="form-control" placeholder="Breedte" disabled={props.disabled} required min={1} />}
				</Field>
				<span className="input-group-text">mm</span>
			</div>
		</>
	);
};

export const DoorBarCutoutComponent = (): React.ComponentType<FieldProps> => {
	return ({field, ...props}) => {
		return <DoorBarCutoutField field={field} {...props} />;
	};
};
