import {useQueryClient} from "@tanstack/react-query";
import {toast} from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import {
    PutExpeditionConditionRepresentation
} from "../../../../../modules/api-client/generated";
import {useEkosietMutation} from "../../../../../shared/hooks/useEkosietMutation";
import {NotFound, ValidationFailed} from "../../../../../modules/api-client/Responses";

export const usePutExpeditionCondition = (id: string) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<void, NotFound | ValidationFailed,PutExpeditionConditionRepresentation>(
        async (model: PutExpeditionConditionRepresentation) => {
                await ApiClient.Sm.Order.smOrderIdExpeditionConditionPut(id, undefined, model);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['smOrderIdGet', id]);
                toast.success("Expeditieconditie aangepast.");

            },
            onError: () => {
                toast.error("Expeditieconditie wijzigen mislukt!");
            }
        }
    );
};
