import {HefSchuifSlidingDirection} from '../../../modules/api-client/generated';
import React from 'react';

export type HefSchuifSlidingDirectionSelectProps = React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;

export const HefSchuifSlidingDirectionSelect: React.FC<HefSchuifSlidingDirectionSelectProps> = (props) => {
	const value = props.value as HefSchuifSlidingDirection;

	return (
		<select {...props}>
			<option value={HefSchuifSlidingDirection.LeftSliding}>
				<HefSchuifSlidingDirectionText value={HefSchuifSlidingDirection.LeftSliding} />
			</option>
			<option value={HefSchuifSlidingDirection.RightSliding}>
				<HefSchuifSlidingDirectionText value={HefSchuifSlidingDirection.RightSliding} />
			</option>
		</select>
	);
};

export const HefSchuifSlidingDirectionText: React.FC<{value: HefSchuifSlidingDirection | undefined}> = ({value}) => {
	switch (value) {
		case HefSchuifSlidingDirection.LeftSliding: {
			return <>Links schuivend</>;
		}
		case HefSchuifSlidingDirection.RightSliding: {
			return <>Rechts schuivend</>;
		}
		default:
			return <>{value}</>;
	}
};
