import React, {useLayoutEffect} from "react";
import {Formik, FormikHelpers, useFormikContext} from "formik";
import SubmitButton from "../../../../shared/components/SubmitButton";
import {ValidationErrors} from "../../../../modules/api-client/Responses";
import TextField from "../../../../shared/components/TextField";
import Loading from "../../../../shared/components/Loading";
import { useWallConnectionAngleQuery, useWallConnectionAngleUpsertMutation } from "./hooks";

export interface WallConnectionAngleFormValues {
    sillWidth: number;
    angle: number;
}

type Props = {
    handleClose: () => void,
    id?: string,
}

const WallConnectionAngleUpsert: React.FC<Props> = ({handleClose,id}) => {

    const {mutate, serverValidationErrors} = useWallConnectionAngleUpsertMutation(id);

    const onSubmit = (values: WallConnectionAngleFormValues, {setSubmitting}: FormikHelpers<WallConnectionAngleFormValues>) => {

        return new Promise<void>((resolve, reject) => {
            mutate(values, {
                onSuccess: () => {
                    resolve();
                    handleClose();
                },
                onError: () => {
                    reject();
                }
            });

        }).finally(() => setSubmitting(false));
    };

    const {data: wallConnectionAngle, isInitialLoading, isError} = useWallConnectionAngleQuery(id);
    if (isInitialLoading) return <Loading />
    if (isError) return (<>Error...</>)
    if (id && !wallConnectionAngle) return (<>'{id}' niet gevonden.</>)

    const initialValues: WallConnectionAngleFormValues = {
        sillWidth: wallConnectionAngle?.sillWidth ?? 0,
        angle: wallConnectionAngle?.angle ?? 0,
    };

    return (
        <>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                <WallConnectionAngleUpsertForm id={id} serverValidationErrors={serverValidationErrors}/>
            </Formik>
        </>

    );
}

interface WallConnectionAngleUpsertFormProps {
    id: string | undefined;
    serverValidationErrors: ValidationErrors | null;
}

export const WallConnectionAngleUpsertForm: React.FC<WallConnectionAngleUpsertFormProps> = (props) => {
    const {handleSubmit, isSubmitting, errors, setErrors} = useFormikContext<WallConnectionAngleFormValues>();
    const mergeServerErrors = () => {

        if (props.serverValidationErrors) {
            setErrors({...errors, ...props.serverValidationErrors});
        }
    }
    useLayoutEffect(mergeServerErrors, [props.serverValidationErrors, errors, setErrors]);
    return (<form onSubmit={handleSubmit}>


        <TextField row={true} name={'sillWidth'} label={'Dorpelbreedte'}/>

        <TextField row={true} name={'angle'} label={'Hoek'}/>

        <div className="row pt-5">
            <div className="offset-4 col-8 d-flex justify-content-end">
                <SubmitButton type="submit" className="btn btn-primary"
                              isSubmitting={isSubmitting}>Opslaan</SubmitButton>
            </div>
        </div>

    </form>);
}

export default WallConnectionAngleUpsert;