import React, { useEffect, useState, useMemo } from 'react';
import { WeeklySalesSillTypeStatsBySillAndCustomerRow } from '../../../../modules/api-client/generated';
import GetYearColor from "../../../../shared/components/GetYearColor";
import { Chart } from "react-chartjs-2";
import { useQuery } from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import { Button, Modal } from 'react-bootstrap';
import { EkoCard, EkoCardBody } from "../../../../shared/components/card";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { EkoTable, TableHeader } from "../../../../shared/components/table";
import { NumericFormat } from "react-number-format";

type Props = {
    customerIds?: string[];
    title?: string|undefined;
    startDate?: Date;
    endDate?: Date;
    data?: {
        [key: string]: { [key: string]: WeeklySalesSillTypeStatsBySillAndCustomerRow; };
    }
}

type YearWeek = {
    year: number,
    week: number
}

const getValueToDisplay = (rows: WeeklySalesSillTypeStatsBySillAndCustomerRow[], showCount: boolean) => {
    if (!rows) return 0;
    return showCount
        ? rows.reduce((count, row) => count + row.sillCount, 0)
        : rows.reduce((length, row) => length + row.sillLength, 0) * 0.001;
};

const isSillFoundInWeek = (data: any, sillId: string, week: YearWeek) => {
    const weekFound = data[`${week.year}-${week.week}`];
    return weekFound ? weekFound[sillId] !== undefined : false;
};

export const SillStatsBySillNameTile: React.FC<Props> = ({ startDate, endDate, customerIds, title }) => {
    const [startDateState, setStartDateState] = useState<Date>(startDate || new Date(2023, 0, 1));
    const [endDateState, setEndDateState] = useState<Date>(endDate || new Date(new Date().setMonth(11, 31)));
    const [showCount, setShowCount] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        if (startDate) setStartDateState(startDate);
    }, [startDate]);

    useEffect(() => {
        if (endDate) setEndDateState(endDate);
    }, [endDate]);

    const { data, isInitialLoading, error : error, isError } = useQuery(
        ['weeklySillStatsBySillName', startDateState, endDateState, null, customerIds],
        () => ApiClient.Sm.Report.weeklySalesSillTypeStatsBySillAndCustomer(startDateState.toDateString()!, endDateState.toDateString()!, undefined, customerIds).then((res) => res.data)
    );

    const uniqueWeeks = useMemo(() => {
        if (!data) return [];
        const uniqueYearWeeksSet = Object.keys(data);
        return Array.from(uniqueYearWeeksSet).map(key => {
            const [year, week] = key.split('-').map(Number);
            return { year, week };
        }).sort((a, b) => a.year - b.year || a.week - b.week);
    }, [data]);

    const allWeeks = useMemo(() => {
        if (uniqueWeeks.length === 0) return [];
        const startYear = uniqueWeeks[0].year;
        const startWeek = uniqueWeeks[0].week;
        const endYear = uniqueWeeks[uniqueWeeks.length - 1].year;
        const endWeek = uniqueWeeks[uniqueWeeks.length - 1].week;

        const weeks = [];
        for (let year = startYear; year <= endYear; year++) {
            const start = year === startYear ? startWeek : 1;
            const end = year === endYear ? endWeek : 53;
            for (let week = start; week <= end; week++) {
                weeks.push({ year, week });
            }
        }
        return weeks;
    }, [uniqueWeeks]);

    const sillNamesById = useMemo(() => {
        if (!data) return {};
        const names: { [key: string]: string } = {};
        for (const weekName in data) {
            for (const sillId in data[weekName]) {
                names[sillId] = data[weekName][sillId][0].sillName;
            }
        }
        return names;
    }, [data]);

    const sillTypesWithCountSorted = useMemo(() => {
        if (!data) return [];
        const counts: { [key: string]: number } = {};
        for (const weekName in data) {
            for (const sillId in data[weekName]) {
                if (!counts[sillId]) counts[sillId] = 0;
                counts[sillId] += getValueToDisplay(data[weekName][sillId], showCount);
            }
        }
        return Object.entries(counts).sort((a, b) => b[1] - a[1]);
    }, [data, showCount]);

    const barChartOptions: any = {
        maintainAspectRatio: true,
        responsive: true,
        plugins: {
            legend: { display: false },
            title: {
                display: true,
                text: showCount ? 'Totaal Aantal dorpels' : 'Totale lengte (m)',
                font: { size: 15, letterSpacing: '0.1em' }
            },
        },
        scales: { x: { stacked: true }, y: { stacked: true } },
    };

    if (isInitialLoading) return <div>Loading...</div>;
    if (isError) return <div>Error: {(error as TypeError)?.message}</div>;
    if (!data) return <div>No data</div>;

    const barChartData = ({
        labels: allWeeks.map(week => `${week.year}-${week.week}`),
        datasets: sillTypesWithCountSorted.map(sill => ({
            label: sillNamesById[sill[0]],
            data: allWeeks.map(yearWeek => isSillFoundInWeek(data, sill[0], yearWeek) ? getValueToDisplay(data[`${yearWeek.year}-${yearWeek.week}`][sill[0]], showCount) : 0),
            backgroundColor: GetYearColor(sill[0]),
        })),
    });


    return (
        <EkoCard className={'mb-5 mb-xl-8'}>
            <div className="card card-flush mb-4">
                <div className="card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-100px bg-info" style={{ backgroundImage: `url('${toAbsoluteUrl('/media/svg/shapes/wave-bg-purple.svg')}')` }}>
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <h2 className="card-title text-white pt-5">
                            <span className="fw-bold fs-2x mb-3">{title}</span>
                        </h2>
                    </div>
                </div>
                <EkoCardBody className={'p-4 mt-n15 position-relative'}>
                    <div className="row g-3 g-lg-6">
                        <div className="col-12">
                            <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="m-0">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <Chart type='bar' options={barChartOptions} data={barChartData} />
                                    </div>
                                    <div className='form-check form-check-reverse form-switch form-control-sm ms-3 pt-5 '>
                                        <label className='form-check-label' htmlFor='toggleMode'>Toon aantallen</label>
                                        <input className='form-check-input' type='checkbox' role='switch'
                                               onChange={() => setShowCount(!showCount)}
                                               checked={showCount}
                                               id='toggleMode'/>
                                    </div>
                                    <EkoTable className="table table-striped table-hover mt-4">
                                        <TableHeader>
                                            <th>Dorpel</th>
                                            <th className="text-end">{showCount ? 'Totaal Aantal dorpels' : 'Totale lengte (m)'}</th>
                                        </TableHeader>
                                        <tbody>
                                        {sillTypesWithCountSorted.map(sill => (
                                            <tr key={sill[0]}>
                                                <td>{sillNamesById[sill[0]]}</td>
                                                <td className="text-end">
                                                    <NumericFormat
                                                        className={'me-1 text-nowrap'} decimalScale={2}
                                                        value={sill[1]} displayType={'text'}
                                                        thousandSeparator={true} suffix={''}/>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </EkoTable>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={showModal} onHide={() => setShowModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Legenda</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ul>
                                {sillTypesWithCountSorted.map(sill => (
                                    <li key={sill[0]}>
                                        <span style={{
                                            backgroundColor: GetYearColor(sill[0]),
                                            display: 'inline-block',
                                            width: '20px',
                                            height: '20px',
                                            marginRight: '10px'
                                        }}></span>
                                        {sillNamesById[sill[0]]}
                                    </li>
                                ))}
                            </ul>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>Sluiten</Button>
                        </Modal.Footer>
                    </Modal>
                </EkoCardBody>
            </div>
        </EkoCard>
    );
};

SillStatsBySillNameTile.defaultProps = {
    title: 'Dorpels per type',
    startDate: new Date(new Date().getFullYear(), 0, 1),
    endDate: new Date(new Date().getFullYear(), 11, 31)
};