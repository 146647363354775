import React from 'react';

type WebcamStreamProps = {
    id: string;
    title: string;
    onCapture: (imageSrc: string) => void;
};

export class WebcamStream extends React.Component<WebcamStreamProps> {
    private videoTag: React.RefObject<HTMLVideoElement>;
    private canvasRef: React.RefObject<HTMLCanvasElement>;
    private stream: MediaStream | null = null;

    constructor(props: WebcamStreamProps) {
        super(props);
        this.videoTag = React.createRef();
        this.canvasRef = React.createRef();
    }

    captureImage = () => {
        if (this.videoTag.current && this.canvasRef.current) {
            const video = this.videoTag.current;
            const canvas = this.canvasRef.current;
            const context = canvas.getContext('2d');

            if (context) {
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                context.drawImage(video, 0, 0, canvas.width, canvas.height);
                const imageSrc = canvas.toDataURL('image/png');
                this.props.onCapture(imageSrc);
            }
        }
    }
    componentDidMount() {
        // getting access to webcam
        navigator.mediaDevices
            .getUserMedia({ video: true })
            .then(stream => {
                this.stream = stream;
                this.videoTag.current!.srcObject = stream;
            })
            .catch(console.log);
    }


    componentWillUnmount() {
        this.turnOffWebcam();
    }

    turnOffWebcam = () => {
        if (this.stream) {
            this.stream.getTracks().forEach(track => track.stop());
        }
    }
    render() {
        return (
            <div>
                <video id={this.props.id}
                      ref={this.videoTag}
                      autoPlay
                      title={this.props.title}></video>
                <canvas ref={this.canvasRef} style={{display: 'none'}}></canvas>
            </div>
        );
    }
}