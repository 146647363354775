import {useQueryClient} from "@tanstack/react-query";
import {toast} from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import {
    PutProjectDiscountRepresentation
} from "../../../../../modules/api-client/generated";
import {useEkosietMutation} from "../../../../../shared/hooks/useEkosietMutation";
import {NotFound, ValidationFailed} from "../../../../../modules/api-client/Responses";

export const usePutProjectDiscount = (id: string) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<void, NotFound | ValidationFailed,PutProjectDiscountRepresentation>(
        async (model: PutProjectDiscountRepresentation) => {
                await ApiClient.Sm.Order.smOrderIdFinancialProjectDiscountPut(id, undefined, model);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['smOrderIdGet', id]);
                queryClient.invalidateQueries(['OrderActionLog', id]);
                toast.success("Projectkorting aangepast.");
            },
            onError: () => {
                toast.error("Projectkorting wijzigen mislukt!");
            }
        }
    );
};
