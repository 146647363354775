/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ReactElement} from 'react'
import {EkoCard, EkoCardBody, KTSVG} from "../../../../../../../_metronic/helpers";
import {Address} from "./Address";
import {Person} from "./Person";
import {
    OrderAddressRepresentation,
    DeliveryConditions,
    OrderContactPersonRepresentation,
    OrderStatusCodes,
} from '../../../../../../modules/api-client/generated';
import {PutContactForm} from "./forms/PutContactForm";
import {OrderContactTypes} from "../../hooks/use-put-contact";
import {PutAddressForm} from "./forms/PutAddressForm";
import {OrderAddressTypes} from "../../hooks/use-put-address";
import {PutExpeditionConditionForm} from "./forms/PutExpeditionConditionForm";
import {PutExpeditionDateForm} from "./forms/PutExpeditionDateForm";
import {formatDate} from '../../../../../../shared/components/date';
import {OrderExpeditionStatus} from "./OrderExpeditionStatus";
import {PutPlannedExpeditionDateForm} from './forms/PutPlannedExpeditionDateForm';
import {EkoCardHeader} from "../../../../../../shared/components/card/EkoCardHeader";
import {EkoCardToolbar} from "../../../../../../shared/components/card/EkoCardToolbar";
import BooleanBadge from "../../../../../../shared/components/BooleanBadge";
import {PutUrgentForm} from "./forms/PutUrgentForm";
import UrgentBadge from "../../../../../../shared/components/UrgentBadge";
import {ProofOfDeliveryOverview} from './ProofOfDeliveryOverview';
import {tr} from 'date-fns/locale';

type ProofOfDelivery = {
    fileId: string;
    blobName: string;
    fileUrl: string;
    proofOfDeliveryType: string;
    creationDate: string;
    creationUsername: string;
}

type Props = {
    deliverycondition?: DeliveryConditions
    preferredExpeditionDate?: Date | null
    plannedExpeditionDate?: Date | null
    deliveredDate?: Date | null
    address?: OrderAddressRepresentation
    person?: OrderContactPersonRepresentation
    status?: string
    urgent: boolean
    expeditionStatus?: string | null
    orderId?: string
    customerId: string
    setModalComponent: React.Dispatch<React.SetStateAction<ReactElement | undefined | null>>,
    setModalTitle: React.Dispatch<React.SetStateAction<string | undefined | null>>,
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>,
    proofOfDeliveries?: ProofOfDelivery[] | null
}
const ShippingDetails: React.FC<Props> = ({
                                              person,
                                              preferredExpeditionDate,
                                              plannedExpeditionDate,
                                              deliveredDate,
                                              address,
                                              deliverycondition,
                                              status,
                                              customerId,
                                              setShowEditModal,
                                              setModalTitle,
                                              setModalComponent,
                                              urgent,
                                              orderId,
                                              expeditionStatus,
                                                proofOfDeliveries
                                          }) => {
    if (!orderId) return (<></>)

    var editContact = () => {
        setModalComponent(() => <PutContactForm handleClose={() => setShowEditModal(false)} customerId={customerId}
                                                type={OrderContactTypes.expedition} contact={person} id={orderId}/>)
        setModalTitle('Wijzig contactpersoon voor expeditie')
        setShowEditModal(true)
    }

    var editExpeditionCondition = () => {
        setModalComponent(() => <PutExpeditionConditionForm handleClose={() => setShowEditModal(false)}
                                                            expeditionCondition={deliverycondition} id={orderId}/>)
        setModalTitle('Wijzig leveringsconditie')
        setShowEditModal(true)
    }

    var editExpeditionAddress = () => {
        setModalComponent(() => <PutAddressForm handleClose={() => setShowEditModal(false)}
                                                type={OrderAddressTypes.expedition} address={address} id={orderId}/>)
        setModalTitle('Wijzig afleveradres')
        setShowEditModal(true)
    }

    var editExpeditionDate = () => {
        setModalComponent(() => <PutExpeditionDateForm handleClose={() => setShowEditModal(false)}
                                                       expeditionDate={preferredExpeditionDate} id={orderId}/>)
        setModalTitle('Wijzig gewenste leveringsdatum')
        setShowEditModal(true)
    }

    var editPlannedExpeditionDate = () => {
        setModalComponent(() => <PutPlannedExpeditionDateForm
            handleClose={() => setShowEditModal(false)}
            plannedExpeditionDate={plannedExpeditionDate}
            updateStateToPlanned={true}
            id={orderId}/>)
        setModalTitle('Wijzig geplande leveringsdatum')
        setShowEditModal(true)
    }
    var editUrgent = () => {
        setModalComponent(() =>
            <PutUrgentForm handleClose={() => setShowEditModal(false)} urgent={urgent} id={orderId}/>,
        );
        setModalTitle('Wijzig spoed label');
        setShowEditModal(true);
    };

    var viewProofOfDelivery = (pod: ProofOfDelivery) => {
        setModalComponent(() =>
            <ProofOfDeliveryOverview url={pod.fileUrl} date={pod.creationDate} username={pod.creationUsername}/>
        );
        setModalTitle('Bewijs van ontvangst');
        setShowEditModal(true);
    }

    return (
		<>
			<EkoCard className={'py-4 h-100'} flush={true}>
				<EkoCardHeader title={`Levering`}>
					<EkoCardToolbar>
						<div className={'d-flex justify-content-between align-items-center'}>
							<div className={'me-3'}>
								<UrgentBadge value={urgent} />
							</div>

							<OrderExpeditionStatus orderId={orderId} status={expeditionStatus} />
						</div>
					</EkoCardToolbar>
				</EkoCardHeader>

				<EkoCardBody className={'pt-0'}>
					<div className="table-responsive">
						{/*begin::Table*/}
						<table className="table align-middle table-row-bordered mb-0 fs-6 gy-5">
							{/*begin::Table body*/}
							<tbody className="fw-semibold text-gray-600">
								{/*begin:: Gewenste levering*/}
								<tr>
									<td className="text-muted">
										<div className="d-flex align-items-center">
											<KTSVG path={'/media/icons/duotune/general/gen014.svg'} className="svg-icon-2 me-2" />
											Gewenste levering
										</div>
									</td>

									<td className="text-end">
										{status === OrderStatusCodes.Draft || status === OrderStatusCodes.Placed || status === OrderStatusCodes.Accepted || status === OrderStatusCodes.Completed ? (
											<a href="#" onClick={() => editExpeditionDate()}>
												{!preferredExpeditionDate && <>Zo spoedig mogelijk</>}

												{preferredExpeditionDate && formatDate(preferredExpeditionDate)}
											</a>
										) : (
											<>
												{!preferredExpeditionDate && <>Zo spoedig mogelijk</>}
												{preferredExpeditionDate && formatDate(preferredExpeditionDate)}
											</>
										)}
									</td>
								</tr>
								{/*end:: Gewenste levering*/}

								{/*begin:: Geplande levering*/}
								<tr>
									<td className="text-muted">
										<div className="d-flex align-items-center">
											<KTSVG path={'/media/icons/duotune/general/gen014.svg'} className="svg-icon-2 me-2" />
											Geplande levering
										</div>
									</td>

									<td className="text-end">
										{status === OrderStatusCodes.Draft || status === OrderStatusCodes.Placed || status === OrderStatusCodes.Accepted || status === OrderStatusCodes.Completed ? (
											<a href="#" onClick={() => editPlannedExpeditionDate()}>
												{!plannedExpeditionDate && <>Niet gepland</>}

												{plannedExpeditionDate && formatDate(plannedExpeditionDate)}
											</a>
										) : (
											<>
												{!plannedExpeditionDate && <>Niet gepland</>}

												{plannedExpeditionDate && formatDate(plannedExpeditionDate)}
											</>
										)}
									</td>
								</tr>
								{/*end:: Geplande levering*/}

								{/*begin:: Gerealiseerde levering*/}
								{deliveredDate && (
									<>
										<tr>
											<td className="text-muted">
												<div className="d-flex align-items-center">
													<KTSVG path={'/media/icons/duotune/general/gen014.svg'} className="svg-icon-2 me-2" />
													Gerealiseerde levering
												</div>
											</td>

											<td className="text-end">{formatDate(deliveredDate)}</td>
										</tr>
									</>
								)}
								{/*end:: Gerealiseerde levering*/}
								{/*begin:: SPOED*/}
								<tr>
									<td className="text-muted">
										<div className="d-flex align-items-center">
											<KTSVG path={'/media/icons/duotune/general/gen002.svg'} className="svg-icon-2 me-2" />
											SPOED
										</div>
									</td>
									<td className="text-end">
										<a href="#" onClick={() => editUrgent()}>
											<div className={''}>
												<i className={'fas fa-edit me-1'}></i>
												<BooleanBadge value={urgent} />
											</div>
										</a>
									</td>
								</tr>
								{/*end:: SPOED*/}
								<tr>
									<td className="text-muted">
										<div className="d-flex align-items-center">
											{/*begin::Svg Icon | path: icons/duotune/ecommerce/ecm006.svg*/}
											<KTSVG path={'/media/icons/duotune/ecommerce/ecm006.svg'} className="svg-icon-2 me-2" />
											{/*end::Svg Icon*/} Levering
										</div>
									</td>

									<td className="text-end">
										{status === OrderStatusCodes.Draft || status === OrderStatusCodes.Placed || status === OrderStatusCodes.Accepted || status === OrderStatusCodes.Completed ? (
											<a href="#" onClick={() => editExpeditionCondition()}>
												<div className={'fw-bold'}>{deliverycondition === DeliveryConditions.Bezorging ? <>Bezorgen</> : <>Af Fabriek</>}</div>
											</a>
										) : (
											<>
												<div className={'fw-bold'}>{deliverycondition === DeliveryConditions.Bezorging ? <>Bezorgen</> : <>Af Fabriek</>}</div>
											</>
										)}
										{deliverycondition === DeliveryConditions.Bezorging && (
											<div className={'mt-3'}>
												{status === OrderStatusCodes.Draft ||
												status === OrderStatusCodes.Placed ||
												status === OrderStatusCodes.Accepted ||
												status === OrderStatusCodes.Completed ? (
													<a href="#" onClick={() => editExpeditionAddress()}>
														{address ? (
															<Address address={address} />
														) : (
															<div className={'text-danger'}>
																<i className={'fas fa-exclamation-triangle'}></i> Voeg adres toe
															</div>
														)}
													</a>
												) : (
													<>
														<Address address={address} />
													</>
												)}
											</div>
										)}
									</td>
								</tr>

								<tr>
									<td className="text-muted">
										<div className="d-flex align-items-center">
											{/*begin::Svg Icon | path: icons/duotune/ecommerce/ecm008.svg*/}
											<KTSVG path={'/media/icons/duotune/finance/fin008.svg'} className="svg-icon-2 me-2" />
											{/*end::Svg Icon*/} Contactpersoon
										</div>
									</td>
									<td className="text-end">
										{status === OrderStatusCodes.Draft || status === OrderStatusCodes.Placed || status === OrderStatusCodes.Accepted || status === OrderStatusCodes.Completed ? (
											<a href="#" onClick={() => editContact()}>
												{person ? (
													<Person person={person} />
												) : (
													<div className={'text-danger'}>
														<i className={'fas fa-exclamation-triangle'}></i> Voeg contactpersoon toe
													</div>
												)}
											</a>
										) : (
											<Person person={person} />
										)}
									</td>
								</tr>
								{proofOfDeliveries && proofOfDeliveries.length > 0 && (
									proofOfDeliveries.map(pod =>
									<tr>
										<td className="text-muted">
											<div className="d-flex align-items-center">
												<i className="fas fa-signature me-2"></i>
												Afleverbewijs
											</div>
										</td>
										<td className="text-end">
											<a href="#" onClick={() => viewProofOfDelivery(pod)}>
												{pod.proofOfDeliveryType === "deliverySignature" ? "Handtekening" : "Foto"}
											</a>
										</td>
									</tr>
								))}
							</tbody>
							{/*end::Table body*/}
						</table>
						{/*end::Table*/}
					</div>
				</EkoCardBody>
			</EkoCard>
		</>
	);
}

export {ShippingDetails}
