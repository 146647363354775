import {ModalFormChildrenComponent, ModalFormChildrenComponentProps, useModalForm, UseModalFormConfig} from '../../../../../shared/components/ModalForm';
import {
	UpsertCompartmentLayoutRequest,
	CompartmentLayoutTypeCode,
	SillCompartmentLayoutTypeOption,
	SillDetailsCompartmentLayout,
	SillDetailsCompartmentLayoutHefSchuifA,
	SillDetailsCompartmentLayoutHefSchuifD,
	SillDetailsCompartmentLayoutHefSchuifE,
} from '../../../../../modules/api-client/generated';
import React, {FC, useRef} from 'react';
import {Form, useFormikContext, FieldProps} from 'formik';
import {Button, Modal} from 'react-bootstrap';
import SpinnerButton from '../../../../../shared/components/SpinnerButton';
import EkoFormField from '../../../../../shared/components/EkoFormField';
import {CompartmentLayoutTypePicker} from '../../compartment-layout-type/CompartmentLayoutTypePicker';
import {
	HefSchuifSlidingDirectionSelect
} from '../../../../../shared/components/configurator/HefSchuifSlidingDirectionSelect';
import {
	HefSchuifInnerDoorPositionSelect
} from '../../../../../shared/components/configurator/HefSchuifInnerDoorPositionSelect';

export const useSillCompartmentLayoutUpsertModal = (config: {save: (compartmentLayoutTypeId: string, value: UpsertCompartmentLayoutRequest) => Promise<void>}) => {
	const compartmentLayoutType = useRef<SillCompartmentLayoutTypeOption | null>(null);

	const modalFormConfig: UseModalFormConfig<UpsertCompartmentLayoutRequest> = {
		onSubmit: async (value: UpsertCompartmentLayoutRequest) => await config.save(compartmentLayoutType.current!.id, value),
	};

	const {open: openModal, render} = useModalForm<UpsertCompartmentLayoutRequest>(modalFormConfig);

	const open = (clt: SillCompartmentLayoutTypeOption, compartmentLayout: SillDetailsCompartmentLayout | null) => {
		compartmentLayoutType.current = clt;

		let initialValues: UpsertCompartmentLayoutRequest = {
			$type: 'standard',
		};

		switch (clt.code) {
			case CompartmentLayoutTypeCode.HefSchuifA: {
				initialValues = {
					$type: 'hsae',
					defaultSlidingDirection: (compartmentLayout as SillDetailsCompartmentLayoutHefSchuifA)?.defaultSlidingDirection ?? 'leftSliding',
				};
				break;
			}
			case CompartmentLayoutTypeCode.HefSchuifD: {
				initialValues = {
					$type: 'hsd',
					defaultInnerDoorPosition: (compartmentLayout as SillDetailsCompartmentLayoutHefSchuifD)?.defaultInnerDoorPosition ?? 'leftSliding',
				};
				break;
			}
			case CompartmentLayoutTypeCode.HefSchuifE: {
				initialValues = {
					$type: 'hsae',
					defaultSlidingDirection: (compartmentLayout as SillDetailsCompartmentLayoutHefSchuifE)?.defaultSlidingDirection ?? 'leftSliding',
				};
				break;
			}
		}

		openModal(initialValues);
	};

	const element = render((props) => <SillCompartmentLayoutUpsertForm {...props} />);

	return {
		open: open,
		element: element,
	};
};

export const SillCompartmentLayoutUpsertForm: FC<ModalFormChildrenComponentProps> = ({isSubmitting, close}) => {
	const {values} = useFormikContext<UpsertCompartmentLayoutRequest>();

	return (
		<Form>
			<Modal.Header closeButton>
				<Modal.Title>Vakindeling toevoegen</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{'defaultSlidingDirection' in values && (
					<EkoFormField
						row={true}
						label={'Standaard schuifrichting'}
						field={{
							name: 'defaultSlidingDirection',
							component: (props: FieldProps<string>) => <HefSchuifSlidingDirectionSelect className="form-select" {...props.field} />,
						}}
					/>
				)}
				{/*{'defaultInnerDoorPosition' in values && 'aap'}*/}

				{'defaultInnerDoorPosition' in values && (
					<EkoFormField
						row={true}
						label={'Standaard positie binnendeur'}
						field={{
							name: 'defaultInnerDoorPosition',
							component: (props: FieldProps<string>) => <HefSchuifInnerDoorPositionSelect className="form-select" {...props.field} />,
						}}
					/>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="link" className="mx-4" onClick={close}>
					Annuleren
				</Button>
				<SpinnerButton type="submit" className="btn btn-primary" spinning={isSubmitting} disabled={isSubmitting}>
					Opslaan
				</SpinnerButton>
			</Modal.Footer>
		</Form>
	);
};
