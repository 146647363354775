import React, {useState} from "react";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {EkoCard, EkoCardBody} from "../../../../shared/components/card";
import {useQuery} from "@tanstack/react-query";
import {DecimalStat, Int32Stat, WeeklySalesSillStatsRow} from "../../../../modules/api-client/generated";
import ApiClient from "../../../../modules/api-client/ApiClient";
import Loading from "../../../../shared/components/Loading";
import {EkoTable, TableHeader} from "../../../../shared/components/table";
import Euro from "../../../../shared/components/Euro";
import {NumericFormat} from "react-number-format";
import {SillStatsTile} from "../../sm/components/SillStatsTile";
import {Doughnut} from "react-chartjs-2";
import {CHART_COLORS, StatsLineChartOptions} from "../../../../shared/components/Chart";
import {SillStatsBySillNameTile} from "../../sm/components/SillStatsBySillNameTile";

type ExtractByValueType<T, ValueType> = {
    [Key in keyof T]: T[Key] extends ValueType ? Key : never;
}[keyof T];
type Int32StatKeys = ExtractByValueType<WeeklySalesSillStatsRow, Int32Stat>;
type DecimalStatKeys = ExtractByValueType<WeeklySalesSillStatsRow, DecimalStat>;

export type Measure = Int32StatKeys | DecimalStatKeys;
export type Mode = keyof Int32Stat | keyof DecimalStat;

export function MgtSillStats() {
    const {data} = useQuery<{
        [key: string]: { [key: string]: WeeklySalesSillStatsRow; };
    }, TypeError, { [key: string]: { [key: string]: WeeklySalesSillStatsRow; }; }>(
        ['weeklySillStats'],
        () => ApiClient.Sm.Report.weeklySalesSillStats().then((res) => res.data));
    const [showDorpelOmzet, setShowDorpelOmzet] = useState(false);
    const [measure, setMeasure] = useState('dorpels' as Measure);
    if (!data) return <Loading/>

    const years = Object.keys(data).map(Number);
    const highestYear = Math.max(...years);

// Get the highest week for the highest year
    const weeks = Object.keys(data[highestYear]).map(Number);
    const highestWeek = Math.max(...weeks);

// Retrieve the data for the highest year and week
    const latestData = data[highestYear][highestWeek];

    const graphData = {
        labels: ['Binnensponning', 'Buitensponning', 'Hefschuif', 'Vouwwand', 'Zijlicht'],
        datasets: [{
            tension: 0.3,
            label: 'Dorpel type',
            borderColor: '#fff',
            borderWidth: 2,
            backgroundColor: Object.values(CHART_COLORS),
            pointRadius: 3,
            pointHoverRadius: 5,
            data: [
                latestData?.percBinnensponning?.ytd,
                latestData?.percBuitensponning?.ytd,
                latestData?.percHefSchuif?.ytd,
                latestData?.percVouwwand?.ytd,
                latestData?.percZijlicht?.ytd,
            ]
        }]
    };

    const options: any = {
        StatsLineChartOptions, ...{
            plugins: {
                legend: {
                    display: true,
                    labels: {
                        backgroundColor: '#9BD0F5',
                        borderColor: '#36A2EB',
                        color: '#fff',
                    }
                },
            },
        }
    }
    return (
        <>
            <PageTitle toolbar={
                <>
                    <div className='gap-2 d-flex'>
                        <div className='form-check form-switch'>
                            <input className='form-check-input' type='checkbox' role='switch'
                                   onChange={() => setShowDorpelOmzet(!showDorpelOmzet)}
                                   checked={showDorpelOmzet}
                                   id='showDorpelOmzet'/>
                            <label className='form-check-label' htmlFor='showDorpelOmzet'>Toon financiele
                                gegevens</label>
                        </div>

                    </div>
                </>
            }>Dorpel statistieken</PageTitle>
            <p>Wekelijkse dorpel statistieken (gebaseerd op verkochte dorpels).</p>
            <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                <div className='col col-lg-8 mb-md-5 mb-xl-10'>
                    {Object.keys(data).sort((a: any, b: any) => b - a).map((year) => (
                        <div key={year}>
                            <h1>{year}</h1>
                            <EkoCard>
                                <EkoCardBody>
                                    <EkoTable classNameDivWrapper={'tableFixHead tableFixFirstColumn'}
                                              className={'table-striped table-striped-columns table-bordered'}
                                              condensed={true}>
                                        <TableHeader>
                                            <th>Week</th>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='ordersWithSills'>Orders met dorpels</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='dorpels'>Dorpels</MeasureTH>
                                            {showDorpelOmzet && (
                                                <MeasureTH measure={measure} setMeasure={setMeasure}
                                                           measureValue='sillSales'>Prijs</MeasureTH>
                                            )}
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='dorpelLengte'>Lengte</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='binnensponning'>Binnensponning</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='buitensponning'>Buitensponning</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='hefSchuif'>Hefschuif</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='vouwwand'>Vouwwand</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='zijlicht'>Zijlicht</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='neuten'>Neuten</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='voormontage'>Voormontage</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='namontage'>Namontage</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='inpakken'>Inpak</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='kopisolatoren'>Kopisolatoren</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='manchettes'>Manchettes</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='latten'>Latten</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='glaslatten'>Glaslatten</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='aantalHoekstukjes'>Hoekstukjes</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='beglazingsprofielen'>Beglazingsprofielen</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='lProfielen'>LProfielen</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='aanslagprofielen'>Aanslagprofielen</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='sluitpotten'>Sluitpotten</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='boorgaten'>Boorgaten</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='kalksponning'>Kalksponning</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='groeven'>Groeven</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='renovatiesponningen'>Renovatiesponningen</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='aantalVakken'>Vakken</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='hefschuifE'>Hefschuif E</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='hefSchuifA'>Hefschuif A</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='hefSchuifD'>Hefschuif D</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='hefSchuifC'>Hefschuif C</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='vouwwandBinnen'>Vouwwand Binnen</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='vouwwandBuiten'>Vouwwand Buiten</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='draaiKiep'>Draaikiep</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='deurBinnendraaiend'>Deur Binnen</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='deurBuitendraaiend'>Deur Buiten</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='vastglasBinnen'>Vastglas binnen</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='vastglasBuiten'>Vastglas buiten</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='slidingRailsHoogAantal'>Rails hoog</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='slidingRailsLaagAantal'>Rails laag</MeasureTH>
                                            <MeasureTH measure={measure} setMeasure={setMeasure}
                                                       measureValue='fixedDoorBars'>Lat vaste deur</MeasureTH>
                                        </TableHeader>
                                        <tbody>
                                        {Object.keys(data[year]).sort((a: any, b: any) => b - a).map((week, index) => (
                                            <React.Fragment key={index}>
                                                {index === 0 && (
                                                    <tr key={'ytd'}>
                                                        <td><strong>YTD</strong></td>
                                                        <td>
                                                            <MeasureDiv measure={measure}
                                                                        setMeasure={setMeasure}
                                                                        measureValue='ordersWithSills'>
                                                                <NumericFormat
                                                                    className={'me-1 text-nowrap'} decimalScale={2}
                                                                    value={data[year][week].ordersWithSills.ytd}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true} suffix={''}/>
                                                            </MeasureDiv>
                                                        </td>
                                                        <td>
                                                            <MeasureDiv measure={measure}
                                                                        setMeasure={setMeasure}
                                                                        measureValue='dorpels'>
                                                                <NumericFormat
                                                                    className={'me-1 text-nowrap'} decimalScale={2}
                                                                    value={data[year][week].dorpels.ytd}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true} suffix={''}/>
                                                            </MeasureDiv>
                                                        </td>
                                                        {showDorpelOmzet && (
                                                            <td>
                                                                <div className={'d-flex flex-column'}>
                                                                    <MeasureDiv measure={measure}
                                                                                setMeasure={setMeasure}
                                                                                measureValue='sillSales'>
                                                                        <Euro value={data[year][week].sillSales.ytd}/>
                                                                    </MeasureDiv>
                                                                    <MeasureDiv measure={measure}
                                                                                setMeasure={setMeasure}
                                                                                measureValue='gemDorpelPrijs'>
                                                                             <span className='text-muted'>
                                                                                <Euro
                                                                                    value={data[year][week].gemDorpelPrijs.ytd}/>
                                                                             </span>
                                                                    </MeasureDiv>
                                                                </div>
                                                            </td>
                                                        )}
                                                        <td>

                                                            <div className={'d-flex flex-column'}>
                                                                <MeasureDiv measure={measure}
                                                                            setMeasure={setMeasure}
                                                                            measureValue='dorpelLengte'>
                                                                    <NumericFormat
                                                                        className={'me-1 text-nowrap'} decimalScale={2}
                                                                        value={data[year][week].dorpelLengte.ytd / 1000}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true} suffix={'m'}/>
                                                                </MeasureDiv>
                                                                <MeasureDiv measure={measure}
                                                                            setMeasure={setMeasure}
                                                                            measureValue='gemDorpelLengte'>
                                                                <span className='text-muted'>
                                                                          <NumericFormat
                                                                              className={'me-1 text-nowrap'}
                                                                              decimalScale={2}
                                                                              value={(data[year][week].gemDorpelLengte.ytd) / 1000}
                                                                              displayType={'text'}
                                                                              thousandSeparator={true} suffix={'m'}/>
                                                            </span>
                                                                </MeasureDiv>

                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className={'d-flex flex-column'}>
                                                                <div className={'text-nowrap'}>
                                                                    <MeasureDiv measure={measure}
                                                                                setMeasure={setMeasure}
                                                                                measureValue='binnensponning'>
                                                                        <NumericFormat
                                                                            className={'me-1 text-nowrap'}
                                                                            decimalScale={2}
                                                                            value={data[year][week].binnensponning.ytd}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true} suffix={''}/>
                                                                    </MeasureDiv>|<MeasureDiv measure={measure}
                                                                                              setMeasure={setMeasure}
                                                                                              measureValue='binnensponningLengte'>
                                                                    <NumericFormat
                                                                        className={'ms-1 text-nowrap'} decimalScale={2}
                                                                        value={data[year][week].binnensponningLengte.ytd / 1000}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true} suffix={'m'}/>
                                                                </MeasureDiv>
                                                                </div>
                                                                <span className='text-muted'>
                                                                                  <MeasureDiv measure={measure}
                                                                                              setMeasure={setMeasure}
                                                                                              measureValue='percBinnensponning'>
                                                                    <NumericFormat
                                                                        className={'me-1 text-nowrap'} decimalScale={2}
                                                                        value={data[year][week].percBinnensponning.ytd}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true} suffix={'%'}/>
                                                                    </MeasureDiv>|<MeasureDiv measure={measure}
                                                                                              setMeasure={setMeasure}
                                                                                              measureValue='gemBinnensponningLengte'>
                                                                    <NumericFormat
                                                                        className={'ms-1 text-nowrap'} decimalScale={2}
                                                                        value={data[year][week].gemBinnensponningLengte.ytd / 1000}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true} suffix={'m'}/>
                                                                    </MeasureDiv>

                                                            </span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className={'d-flex flex-column'}>
                                                                <div className={'text-nowrap'}>
                                                                    <MeasureDiv measure={measure}
                                                                                setMeasure={setMeasure}
                                                                                measureValue='buitensponning'>
                                                                        <NumericFormat
                                                                            className={'me-1 text-nowrap'}
                                                                            decimalScale={2}
                                                                            value={data[year][week].buitensponning.ytd}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true} suffix={''}/>
                                                                    </MeasureDiv>|<MeasureDiv measure={measure}
                                                                                              setMeasure={setMeasure}
                                                                                              measureValue='buitensponningLengte'>
                                                                    <NumericFormat
                                                                        className={'ms-1 text-nowrap'} decimalScale={2}
                                                                        value={data[year][week].buitensponningLengte.ytd / 1000}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true} suffix={'m'}/>
                                                                </MeasureDiv>
                                                                </div>
                                                                <div className='text-muted'>
                                                                    <MeasureDiv
                                                                        measure={measure}
                                                                        setMeasure={setMeasure}
                                                                        measureValue='percBuitensponning'>
                                                                        <NumericFormat
                                                                            className={'me-1 text-nowrap'}
                                                                            decimalScale={2}
                                                                            value={data[year][week].percBuitensponning.ytd}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true}
                                                                            suffix={'%'}/>
                                                                    </MeasureDiv>|<MeasureDiv measure={measure}
                                                                                              setMeasure={setMeasure}
                                                                                              measureValue='gemBuitensponningLengte'>
                                                                    <NumericFormat
                                                                        className={'me-1 text-nowrap'}
                                                                        decimalScale={2}
                                                                        value={(data[year][week].gemBuitensponningLengte.ytd) / 1000}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true} suffix={'m'}/>
                                                                </MeasureDiv>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className={'d-flex flex-column'}>
                                                                <div className={'text-nowrap'}>
                                                                    <MeasureDiv measure={measure}
                                                                                setMeasure={setMeasure}
                                                                                measureValue='hefSchuif'>
                                                                        <NumericFormat
                                                                            className={'me-1 text-nowrap'}
                                                                            decimalScale={2}
                                                                            value={data[year][week].hefSchuif.ytd}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true} suffix={''}/>
                                                                    </MeasureDiv>|<MeasureDiv measure={measure}
                                                                                              setMeasure={setMeasure}
                                                                                              measureValue='hefSchuifLengte'>
                                                                    <NumericFormat
                                                                        className={'ms-1 text-nowrap'} decimalScale={2}
                                                                        value={data[year][week].hefSchuifLengte.ytd / 1000}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true} suffix={'m'}/>
                                                                </MeasureDiv>
                                                                </div>
                                                                <div className='text-muted'>
                                                                    <MeasureDiv
                                                                        measure={measure}
                                                                        setMeasure={setMeasure}
                                                                        measureValue='percHefSchuif'>
                                                                        <NumericFormat
                                                                            className={'me-1 text-nowrap'}
                                                                            decimalScale={2}
                                                                            value={data[year][week].percHefSchuif.ytd}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true}
                                                                            suffix={'%'}/>
                                                                    </MeasureDiv>|<MeasureDiv measure={measure}
                                                                                              setMeasure={setMeasure}
                                                                                              measureValue='gemHefSchuifLengte'>
                                                                    <NumericFormat
                                                                        className={'me-1 text-nowrap'}
                                                                        decimalScale={2}
                                                                        value={(data[year][week].gemHefSchuifLengte.ytd) / 1000}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true} suffix={'m'}/>
                                                                </MeasureDiv>
                                                                </div>


                                                            </div>
                                                        </td>

                                                        <td>
                                                            <div className={'d-flex flex-column'}>

                                                                <div className={'text-nowrap'}>
                                                                    <MeasureDiv measure={measure}
                                                                                setMeasure={setMeasure}
                                                                                measureValue='vouwwand'>
                                                                        <NumericFormat
                                                                            className={'me-1 text-nowrap'}
                                                                            decimalScale={2}
                                                                            value={data[year][week].vouwwand.ytd}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true} suffix={''}/>
                                                                    </MeasureDiv>|<MeasureDiv measure={measure}
                                                                                              setMeasure={setMeasure}
                                                                                              measureValue='vouwwandLengte'>
                                                                    <NumericFormat
                                                                        className={'ms-1 text-nowrap'} decimalScale={2}
                                                                        value={data[year][week].vouwwandLengte.ytd / 1000}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true} suffix={'m'}/>
                                                                </MeasureDiv>
                                                                </div>
                                                                <div className='text-muted'>
                                                                    <MeasureDiv
                                                                        measure={measure}
                                                                        setMeasure={setMeasure}
                                                                        measureValue='percVouwwand'>
                                                                        <NumericFormat
                                                                            className={'me-1 text-nowrap'}
                                                                            decimalScale={2}
                                                                            value={data[year][week].percVouwwand.ytd}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true}
                                                                            suffix={'%'}/>
                                                                    </MeasureDiv>|<MeasureDiv measure={measure}
                                                                                              setMeasure={setMeasure}
                                                                                              measureValue='gemVouwwandLengte'>
                                                                    <NumericFormat
                                                                        className={'me-1 text-nowrap'}
                                                                        decimalScale={2}
                                                                        value={(data[year][week].gemVouwwandLengte.ytd) / 1000}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true} suffix={'m'}/>
                                                                </MeasureDiv>
                                                                </div>

                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className={'d-flex flex-column'}>
                                                                <div className={'text-nowrap'}>
                                                                    <MeasureDiv measure={measure}
                                                                                setMeasure={setMeasure}
                                                                                measureValue='zijlicht'>
                                                                        <NumericFormat
                                                                            className={'me-1 text-nowrap'}
                                                                            decimalScale={2}
                                                                            value={data[year][week].zijlicht.ytd}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true} suffix={''}/>
                                                                    </MeasureDiv>|<MeasureDiv measure={measure}
                                                                                              setMeasure={setMeasure}
                                                                                              measureValue='zijlichtLengte'>
                                                                    <NumericFormat
                                                                        className={'ms-1 text-nowrap'} decimalScale={2}
                                                                        value={data[year][week].zijlichtLengte.ytd / 1000}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true} suffix={'m'}/>
                                                                </MeasureDiv>
                                                                </div>
                                                                <div className='text-muted'>
                                                                    <MeasureDiv
                                                                        measure={measure}
                                                                        setMeasure={setMeasure}
                                                                        measureValue='percZijlicht'>
                                                                        <NumericFormat
                                                                            className={'me-1 text-nowrap'}
                                                                            decimalScale={2}
                                                                            value={data[year][week].percZijlicht.ytd}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true}
                                                                            suffix={'%'}/>
                                                                    </MeasureDiv>|<MeasureDiv measure={measure}
                                                                                              setMeasure={setMeasure}
                                                                                              measureValue='gemZijlichtLengte'>
                                                                    <NumericFormat
                                                                        className={'me-1 text-nowrap'}
                                                                        decimalScale={2}
                                                                        value={(data[year][week].gemZijlichtLengte.ytd) / 1000}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true} suffix={'m'}/>
                                                                </MeasureDiv>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <div className={'d-flex flex-column'}>
                                                                <MeasureDiv measure={measure}
                                                                            setMeasure={setMeasure}
                                                                            measureValue='neuten'>
                                                                    <NumericFormat
                                                                        className={'me-1 text-nowrap'} decimalScale={2}
                                                                        value={data[year][week].neuten.ytd}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true} suffix={''}/>
                                                                </MeasureDiv>


                                                                <div className='text-muted'>
                                                                    <MeasureDiv measure={measure}
                                                                                setMeasure={setMeasure}
                                                                                measureValue='gemAantalNeutPerDorpel'>
                                                                        <NumericFormat
                                                                            className={'ms-1 text-nowrap'}
                                                                            decimalScale={2}
                                                                            value={data[year][week].gemAantalNeutPerDorpel.ytd}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true} suffix={''}/>
                                                                    </MeasureDiv>

                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className={'d-flex flex-column'}>
                                                                <MeasureDiv measure={measure}
                                                                            setMeasure={setMeasure}
                                                                            measureValue='voormontage'>
                                                                    <NumericFormat
                                                                        className={'me-1 text-nowrap'} decimalScale={2}
                                                                        value={data[year][week].voormontage.ytd}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true} suffix={''}/>
                                                                </MeasureDiv>


                                                                <div className='text-muted'>
                                                                    <MeasureDiv measure={measure}
                                                                                setMeasure={setMeasure}
                                                                                measureValue='percVoormontage'>
                                                                        <NumericFormat
                                                                            className={'me-1 text-nowrap'}
                                                                            decimalScale={2}
                                                                            value={data[year][week].percVoormontage.ytd}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true} suffix={'%'}/>
                                                                    </MeasureDiv>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className={'d-flex flex-column'}>
                                                                <MeasureDiv measure={measure}
                                                                            setMeasure={setMeasure}
                                                                            measureValue='namontage'>
                                                                    <NumericFormat
                                                                        className={'me-1 text-nowrap'} decimalScale={2}
                                                                        value={data[year][week].namontage.ytd}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true} suffix={''}/>
                                                                </MeasureDiv>
                                                                <div className='text-muted'>
                                                                    <MeasureDiv measure={measure}
                                                                                setMeasure={setMeasure}
                                                                                measureValue='percNamontage'>
                                                                        <NumericFormat
                                                                            className={'me-1 text-nowrap'}
                                                                            decimalScale={2}
                                                                            value={data[year][week].percNamontage.ytd}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true} suffix={'%'}/>
                                                                    </MeasureDiv>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className={'d-flex flex-column'}>
                                                                <MeasureDiv measure={measure}
                                                                            setMeasure={setMeasure}
                                                                            measureValue='inpakken'>
                                                                    <NumericFormat
                                                                        className={'me-1 text-nowrap'}
                                                                        decimalScale={2}
                                                                        value={data[year][week].inpakken.ytd}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true} suffix={''}/>
                                                                </MeasureDiv>

                                                                <span className='text-muted'>
                                                                          <MeasureDiv measure={measure}
                                                                                      setMeasure={setMeasure}
                                                                                      measureValue='percInpakken'>
                                                                        <NumericFormat
                                                                            className={'me-1 text-nowrap'}
                                                                            decimalScale={2}
                                                                            value={data[year][week].percInpakken.ytd}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true} suffix={'%'}/>
                                                                    </MeasureDiv>
                                                            </span>
                                                            </div>
                                                        </td>


                                                        <td>
                                                            <MeasureDiv measure={measure}
                                                                        setMeasure={setMeasure}
                                                                        measureValue='kopisolatoren'>
                                                                <NumericFormat
                                                                    className={'me-1 text-nowrap'} decimalScale={2}
                                                                    value={data[year][week].kopisolatoren.ytd}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true} suffix={''}/>
                                                            </MeasureDiv>

                                                        </td>
                                                        <td>
                                                            <MeasureDiv measure={measure}
                                                                        setMeasure={setMeasure}
                                                                        measureValue='manchettes'>
                                                                <NumericFormat
                                                                    className={'me-1 text-nowrap'} decimalScale={2}
                                                                    value={data[year][week].manchettes.ytd}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true} suffix={''}/>
                                                            </MeasureDiv>
                                                        </td>
                                                        <td>

                                                            <div className={'d-flex flex-column'}>
                                                                <MeasureDiv measure={measure}
                                                                            setMeasure={setMeasure}
                                                                            measureValue='latten'>
                                                                    <NumericFormat
                                                                        className={'me-1 text-nowrap'} decimalScale={2}
                                                                        value={data[year][week].latten.ytd}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true} suffix={''}/>
                                                                </MeasureDiv>

                                                                <span className='text-muted'>
                                                                          <MeasureDiv measure={measure}
                                                                                      setMeasure={setMeasure}
                                                                                      measureValue='percDorpelsMetLatten'>
                                                                <NumericFormat
                                                                    className={'me-1 text-nowrap'}
                                                                    decimalScale={2}
                                                                    value={data[year][week].percDorpelsMetLatten.ytd}
                                                                    displayType={'text'}
                                                                    title={'Percentage dorpels met latten'}
                                                                    thousandSeparator={true} suffix={'%'}/>
                                                                    </MeasureDiv>
                                                            </span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className={'d-flex flex-column'}>
                                                                <div className={'text-nowrap'}>
                                                                    <MeasureDiv measure={measure}
                                                                                setMeasure={setMeasure}
                                                                                measureValue='glaslatten'>
                                                                        <NumericFormat
                                                                            className={'me-1 text-nowrap'}
                                                                            decimalScale={2}
                                                                            value={data[year][week].glaslatten.ytd}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true} suffix={''}/>
                                                                    </MeasureDiv>
                                                                </div>
                                                                <span className='text-muted'>
                                                                <MeasureDiv measure={measure}
                                                                            setMeasure={setMeasure}
                                                                            measureValue='glasLatLengte'>
                                                                    <NumericFormat
                                                                        className={'ms-1 text-nowrap'} decimalScale={2}
                                                                        value={data[year][week].glasLatLengte.ytd / 1000}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true} suffix={'m'}/>
                                                                </MeasureDiv>
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className={'d-flex flex-column'}>
                                                                <div className={'text-nowrap'}>
                                                                    <MeasureDiv measure={measure}
                                                                                setMeasure={setMeasure}
                                                                                measureValue='aantalHoekstukjes'>
                                                                        <NumericFormat
                                                                            className={'me-1 text-nowrap'}
                                                                            decimalScale={2}
                                                                            value={data[year][week].aantalHoekstukjes.ytd}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true} suffix={''}/>
                                                                    </MeasureDiv>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className={'d-flex flex-column'}>
                                                                <MeasureDiv measure={measure}
                                                                            setMeasure={setMeasure}
                                                                            measureValue='beglazingsprofielen'>
                                                                    <NumericFormat
                                                                        className={'me-1 text-nowrap'}
                                                                        decimalScale={2}
                                                                        value={data[year][week].beglazingsprofielen.ytd}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true} suffix={''}/>
                                                                </MeasureDiv>
                                                                <span className='text-muted'>
                                                                    <MeasureDiv measure={measure}
                                                                                setMeasure={setMeasure}
                                                                                measureValue='beglazingsprofielLengte'>
                                                                    <NumericFormat
                                                                        className={'ms-1 text-nowrap'} decimalScale={2}
                                                                        value={data[year][week].beglazingsprofielLengte.ytd / 1000}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true} suffix={'m'}/>
                                                                </MeasureDiv>
                                                                    </span>
                                                            </div>

                                                        </td>
                                                        <td>
                                                            <div className={'d-flex flex-column'}>
                                                                <MeasureDiv measure={measure}
                                                                            setMeasure={setMeasure}
                                                                            measureValue='lProfielen'>
                                                                    <NumericFormat
                                                                        className={'me-1 text-nowrap'}
                                                                        decimalScale={2}
                                                                        value={data[year][week].lProfielen.ytd}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true} suffix={''}/>
                                                                </MeasureDiv>
                                                                <span className='text-muted'>
                                                                        <MeasureDiv measure={measure}
                                                                                    setMeasure={setMeasure}
                                                                                    measureValue='lProfielLengte'>
                                                                    <NumericFormat
                                                                        className={'ms-1 text-nowrap'} decimalScale={2}
                                                                        value={data[year][week].lProfielLengte.ytd / 1000}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true} suffix={'m'}/>
                                                                </MeasureDiv>
                                                                    </span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className={'d-flex flex-column'}>
                                                                <MeasureDiv measure={measure}
                                                                            setMeasure={setMeasure}
                                                                            measureValue='aanslagprofielen'>
                                                                    <NumericFormat
                                                                        className={'me-1 text-nowrap'}
                                                                        decimalScale={2}
                                                                        value={data[year][week].aanslagprofielen.ytd}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true} suffix={''}/>
                                                                </MeasureDiv>
                                                                <span className='text-muted'>
                                                                    <MeasureDiv measure={measure}
                                                                                setMeasure={setMeasure}
                                                                                measureValue='aanslagprofielLengte'>
                                                                    <NumericFormat
                                                                        className={'ms-1 text-nowrap'} decimalScale={2}
                                                                        value={data[year][week].aanslagprofielLengte.ytd / 1000}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true} suffix={'m'}/>
                                                                </MeasureDiv>
                                                                    </span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <MeasureDiv measure={measure}
                                                                        setMeasure={setMeasure}
                                                                        measureValue='sluitpotten'>
                                                                <NumericFormat
                                                                    className={'me-1 text-nowrap'}
                                                                    decimalScale={2}
                                                                    value={data[year][week].sluitpotten.ytd}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true} suffix={''}/>
                                                            </MeasureDiv>
                                                        </td>
                                                        <td>
                                                            <MeasureDiv measure={measure}
                                                                        setMeasure={setMeasure}
                                                                        measureValue='boorgaten'>
                                                                <NumericFormat
                                                                    className={'me-1 text-nowrap'}
                                                                    decimalScale={2}
                                                                    value={data[year][week].boorgaten.ytd}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true} suffix={''}/>
                                                            </MeasureDiv>
                                                        </td>
                                                        <td>
                                                            <MeasureDiv measure={measure}
                                                                        setMeasure={setMeasure}
                                                                        measureValue='kalksponning'>
                                                                <NumericFormat
                                                                    className={'me-1 text-nowrap'}
                                                                    decimalScale={2}
                                                                    value={data[year][week].kalksponning.ytd}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true} suffix={''}/>
                                                            </MeasureDiv>

                                                        </td>
                                                        <td>
                                                            <MeasureDiv measure={measure}
                                                                        setMeasure={setMeasure}
                                                                        measureValue='groeven'>
                                                                <NumericFormat
                                                                    className={'me-1 text-nowrap'}
                                                                    decimalScale={2}
                                                                    value={data[year][week].groeven.ytd}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true} suffix={''}/>
                                                            </MeasureDiv>
                                                        </td>
                                                        <td>
                                                            <MeasureDiv measure={measure}
                                                                        setMeasure={setMeasure}
                                                                        measureValue='renovatiesponningen'>
                                                                <NumericFormat
                                                                    className={'me-1 text-nowrap'}
                                                                    decimalScale={2}
                                                                    value={data[year][week].renovatiesponningen.ytd}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true} suffix={''}/>
                                                            </MeasureDiv>
                                                        </td>
                                                        <td>
                                                            <MeasureDiv measure={measure}
                                                                        setMeasure={setMeasure}
                                                                        measureValue='aantalVakken'>
                                                                <NumericFormat
                                                                    className={'me-1 text-nowrap'}
                                                                    decimalScale={2}
                                                                    value={data[year][week].aantalVakken.ytd}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true} suffix={''}/>
                                                            </MeasureDiv>
                                                        </td>
                                                        <td>
                                                            <MeasureDiv measure={measure}
                                                                        setMeasure={setMeasure}
                                                                        measureValue='hefschuifE'>
                                                                <NumericFormat
                                                                    className={'me-1 text-nowrap'} decimalScale={2}
                                                                    value={data[year][week].hefschuifE.ytd}
                                                                    displayType={'text'}
                                                                    title={'Aantal vakken met Hefschuifschema E'}
                                                                    thousandSeparator={true} suffix={''}/>
                                                            </MeasureDiv>
                                                        </td>
                                                        <td>
                                                            <MeasureDiv measure={measure}
                                                                        setMeasure={setMeasure}
                                                                        measureValue='hefSchuifA'>
                                                                <NumericFormat
                                                                    className={'me-1 text-nowrap'} decimalScale={2}
                                                                    value={data[year][week].hefSchuifA.ytd}
                                                                    displayType={'text'}
                                                                    title={'Aantal vakken met Hefschuifschema A'}
                                                                    thousandSeparator={true} suffix={''}/>
                                                            </MeasureDiv>
                                                        </td>
                                                        <td>
                                                            <MeasureDiv measure={measure}
                                                                        setMeasure={setMeasure}
                                                                        measureValue='hefSchuifD'>
                                                                <NumericFormat
                                                                    className={'me-1 text-nowrap'} decimalScale={2}
                                                                    value={data[year][week].hefSchuifD.ytd}
                                                                    displayType={'text'}
                                                                    title={'Aantal vakken met Hefschuifschema D'}
                                                                    thousandSeparator={true} suffix={''}/>
                                                            </MeasureDiv>
                                                        </td>
                                                        <td>
                                                            <MeasureDiv measure={measure}
                                                                        setMeasure={setMeasure}
                                                                        measureValue='hefSchuifC'>
                                                                <NumericFormat
                                                                    className={'me-1 text-nowrap'} decimalScale={2}
                                                                    value={data[year][week].hefSchuifC.ytd}
                                                                    displayType={'text'}
                                                                    title={'Aantal vakken met Hefschuifschema C'}
                                                                    thousandSeparator={true} suffix={''}/>
                                                            </MeasureDiv>
                                                        </td>
                                                        <td>
                                                            <MeasureDiv measure={measure}
                                                                        setMeasure={setMeasure}
                                                                        measureValue='vouwwandBinnen'>
                                                                <NumericFormat
                                                                    className={'me-1 text-nowrap'} decimalScale={2}
                                                                    value={data[year][week].vouwwandBinnen.ytd}
                                                                    displayType={'text'}
                                                                    title={'Aantal vakken met Vouwwand Binnen'}
                                                                    thousandSeparator={true} suffix={''}/>
                                                            </MeasureDiv>
                                                        </td>
                                                        <td>
                                                            <MeasureDiv measure={measure}
                                                                        setMeasure={setMeasure}
                                                                        measureValue='vouwwandBuiten'>
                                                                <NumericFormat
                                                                    className={'me-1 text-nowrap'} decimalScale={2}
                                                                    value={data[year][week].vouwwandBuiten.ytd}
                                                                    displayType={'text'}
                                                                    title={'Aantal vakken met Vouwwand Buiten'}
                                                                    thousandSeparator={true} suffix={''}/>
                                                            </MeasureDiv>
                                                        </td>
                                                        <td>
                                                            <MeasureDiv measure={measure}
                                                                        setMeasure={setMeasure}
                                                                        measureValue='draaiKiep'>
                                                                <NumericFormat
                                                                    className={'me-1 text-nowrap'} decimalScale={2}
                                                                    value={data[year][week].draaiKiep.ytd}
                                                                    displayType={'text'}
                                                                    title={'Aantal vakken met draaikiep indeling'}
                                                                    thousandSeparator={true} suffix={''}/>
                                                            </MeasureDiv>
                                                        </td>
                                                        <td>
                                                            <MeasureDiv measure={measure}
                                                                        setMeasure={setMeasure}
                                                                        measureValue='deurBinnendraaiend'>
                                                                <NumericFormat
                                                                    className={'me-1 text-nowrap'} decimalScale={2}
                                                                    value={data[year][week].deurBinnendraaiend.ytd}
                                                                    displayType={'text'}
                                                                    title={'Aantal vakken met deur binnendraaiend'}
                                                                    thousandSeparator={true} suffix={''}/>
                                                            </MeasureDiv>
                                                        </td>
                                                        <td>
                                                            <MeasureDiv measure={measure}
                                                                        setMeasure={setMeasure}
                                                                        measureValue='deurBuitendraaiend'>
                                                                <NumericFormat
                                                                    className={'me-1 text-nowrap'} decimalScale={2}
                                                                    value={data[year][week].deurBuitendraaiend.ytd}
                                                                    displayType={'text'}
                                                                    title={'Aantal vakken met deurbuitendraaiend indeling'}
                                                                    thousandSeparator={true} suffix={''}/>
                                                            </MeasureDiv>
                                                        </td>
                                                        <td>
                                                            <MeasureDiv measure={measure}
                                                                        setMeasure={setMeasure}
                                                                        measureValue='vastglasBinnen'>
                                                                <NumericFormat
                                                                    className={'me-1 text-nowrap'} decimalScale={2}
                                                                    value={data[year][week].vastglasBinnen.ytd}
                                                                    displayType={'text'}
                                                                    title={'Aantal vakken met vast glas binnen'}
                                                                    thousandSeparator={true} suffix={''}/>
                                                            </MeasureDiv>
                                                        </td>
                                                        <td>
                                                            <MeasureDiv measure={measure}
                                                                        setMeasure={setMeasure}
                                                                        measureValue='vastglasBuiten'>
                                                                <NumericFormat
                                                                    className={'me-1 text-nowrap'} decimalScale={2}
                                                                    value={data[year][week].vastglasBuiten.ytd}
                                                                    displayType={'text'}
                                                                    title={'Aantal vakken met vast glas buiten'}
                                                                    thousandSeparator={true} suffix={''}/>
                                                            </MeasureDiv>
                                                        </td>
                                                        <td>
                                                            <div className={'d-flex flex-column'}>
                                                                <div className={'text-nowrap'}>
                                                                    <MeasureDiv measure={measure}
                                                                                setMeasure={setMeasure}
                                                                                measureValue='slidingRailsHoogAantal'>
                                                                        <NumericFormat
                                                                            className={'me-1 text-nowrap'}
                                                                            decimalScale={2}
                                                                            value={data[year][week].slidingRailsHoogAantal.ytd}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true} suffix={''}/>
                                                                    </MeasureDiv>
                                                                </div>
                                                                <span className='text-muted'>
                                                                <MeasureDiv measure={measure}
                                                                            setMeasure={setMeasure}
                                                                            measureValue='slidingRailsHoogLengte'>
                                                                    <NumericFormat
                                                                        className={'ms-1 text-nowrap'} decimalScale={2}
                                                                        value={data[year][week].slidingRailsHoogLengte.ytd / 1000}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true} suffix={'m'}/>
                                                                </MeasureDiv>
                                                                </span>
                                                            </div>


                                                        </td>
                                                        <td>
                                                            <div className={'d-flex flex-column'}>
                                                                <div className={'text-nowrap'}>
                                                                    <MeasureDiv measure={measure}
                                                                                setMeasure={setMeasure}
                                                                                measureValue='slidingRailsLaagAantal'>
                                                                        <NumericFormat
                                                                            className={'me-1 text-nowrap'}
                                                                            decimalScale={2}
                                                                            value={data[year][week].slidingRailsLaagAantal.ytd}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true} suffix={''}/>
                                                                    </MeasureDiv>
                                                                </div>
                                                                <span className='text-muted'>
                                                                <MeasureDiv measure={measure}
                                                                            setMeasure={setMeasure}
                                                                            measureValue='slidingRailsLaagLengte'>
                                                                    <NumericFormat
                                                                        className={'ms-1 text-nowrap'} decimalScale={2}
                                                                        value={data[year][week].slidingRailsLaagLengte.ytd / 1000}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true} suffix={'m'}/>
                                                                </MeasureDiv>
                                                                </span>
                                                            </div>

                                                        </td>
                                                        <td>
                                                            <div className={'d-flex flex-column'}>
                                                                <div className={'text-nowrap'}>
                                                                    <MeasureDiv measure={measure}
                                                                                setMeasure={setMeasure}
                                                                                measureValue='fixedDoorBars'>
                                                                        <NumericFormat
                                                                            className={'me-1 text-nowrap'}
                                                                            decimalScale={2}
                                                                            value={data[year][week].fixedDoorBars.ytd}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true} suffix={''}/>
                                                                    </MeasureDiv>
                                                                </div>
                                                                <span className='text-muted'>
                                                                <MeasureDiv measure={measure}
                                                                            setMeasure={setMeasure}
                                                                            measureValue='fixedDoorBarLengte'>
                                                                    <NumericFormat
                                                                        className={'ms-1 text-nowrap'} decimalScale={2}
                                                                        value={data[year][week].fixedDoorBarLengte.ytd / 1000}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true} suffix={'m'}/>
                                                                </MeasureDiv>
                                                                </span>
                                                            </div>
                                                        </td>

                                                    </tr>
                                                )}
                                                <tr key={week}>
                                                    <td><strong>{week}</strong></td>
                                                    <td>{data[year][week].ordersWithSills.sum}</td>
                                                    <td>{data[year][week].dorpels.sum}</td>
                                                    {showDorpelOmzet && (
                                                        <td>
                                                            <div className={'d-flex flex-column'}>
                                                                <Euro value={data[year][week].sillSales.sum}/>
                                                                <span className='text-muted'>
                                                                <Euro value={data[year][week].gemDorpelPrijs.sum}/>
                                                            </span>
                                                            </div>
                                                        </td>
                                                    )}
                                                    <td>
                                                        <div className={'d-flex flex-column'}>
                                                            <NumericFormat
                                                                className={'me-1 text-nowrap'} decimalScale={2}
                                                                value={data[year][week].dorpelLengte.sum / 1000}
                                                                displayType={'text'}
                                                                thousandSeparator={true} suffix={'m'}/>
                                                            <span className='text-muted'>
                                                                          <NumericFormat
                                                                              className={'me-1 text-nowrap'}
                                                                              decimalScale={2}
                                                                              value={(data[year][week].gemDorpelLengte.sum) / 1000}
                                                                              displayType={'text'}
                                                                              thousandSeparator={true} suffix={'m'}/>
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className={'d-flex flex-column'}>
                                                            <div className={'text-nowrap'}>
                                                                <NumericFormat
                                                                    className={'me-1 text-nowrap'} decimalScale={2}
                                                                    value={data[year][week].binnensponning.sum}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true} suffix={''}/>|
                                                                <NumericFormat
                                                                    className={'ms-1 text-nowrap'} decimalScale={2}
                                                                    value={data[year][week].binnensponningLengte.sum / 1000}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true} suffix={'m'}/>
                                                            </div>
                                                            <span className='text-muted'>
                                                                       <NumericFormat
                                                                           className={'me-1 text-nowrap'}
                                                                           decimalScale={2}
                                                                           value={data[year][week].percBinnensponning.sum}
                                                                           displayType={'text'}
                                                                           thousandSeparator={true}
                                                                           suffix={'%'}/>
                                                                          <NumericFormat
                                                                              className={'me-1 text-nowrap'}
                                                                              decimalScale={2}
                                                                              value={(data[year][week].gemBinnensponningLengte.sum) / 1000}
                                                                              displayType={'text'}
                                                                              thousandSeparator={true} suffix={'m'}/>
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className={'d-flex flex-column'}>
                                                            <div className={'text-nowrap'}>
                                                                <NumericFormat
                                                                    className={'me-1 text-nowrap'} decimalScale={2}
                                                                    value={data[year][week].buitensponning.sum}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true} suffix={''}/>|
                                                                <NumericFormat
                                                                    className={'ms-1 text-nowrap'} decimalScale={2}
                                                                    value={data[year][week].buitensponningLengte.sum / 1000}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true} suffix={'m'}/>
                                                            </div>
                                                            <span className='text-muted'>
                                                                       <NumericFormat
                                                                           className={'me-1 text-nowrap'}
                                                                           decimalScale={2}
                                                                           value={data[year][week].percBuitensponning.sum}
                                                                           displayType={'text'}
                                                                           thousandSeparator={true}
                                                                           suffix={'%'}/>
                                                                          <NumericFormat
                                                                              className={'me-1 text-nowrap'}
                                                                              decimalScale={2}
                                                                              value={(data[year][week].gemBuitensponningLengte.sum) / 1000}
                                                                              displayType={'text'}
                                                                              thousandSeparator={true} suffix={'m'}/>
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className={'d-flex flex-column'}>
                                                            <div className={'text-nowrap'}>
                                                                <NumericFormat
                                                                    className={'me-1 text-nowrap'} decimalScale={2}
                                                                    value={data[year][week].hefSchuif.sum}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true} suffix={''}/>|
                                                                <NumericFormat
                                                                    className={'ms-1 text-nowrap'} decimalScale={2}
                                                                    value={data[year][week].hefSchuifLengte.sum / 1000}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true} suffix={'m'}/>
                                                            </div>
                                                            <span className='text-muted'>
                                                                       <NumericFormat
                                                                           className={'me-1 text-nowrap'}
                                                                           decimalScale={2}
                                                                           value={data[year][week].percHefSchuif.sum}
                                                                           displayType={'text'}
                                                                           thousandSeparator={true}
                                                                           suffix={'%'}/>
                                                                          <NumericFormat
                                                                              className={'me-1 text-nowrap'}
                                                                              decimalScale={2}
                                                                              value={(data[year][week].gemHefSchuifLengte.sum) / 1000}
                                                                              displayType={'text'}
                                                                              thousandSeparator={true} suffix={'m'}/>
                                                            </span>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className={'d-flex flex-column'}>
                                                            <div className={'text-nowrap'}>
                                                                <NumericFormat
                                                                    className={'me-1 text-nowrap'} decimalScale={2}
                                                                    value={data[year][week].vouwwand.sum}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true} suffix={''}/>|
                                                                <NumericFormat
                                                                    className={'ms-1 text-nowrap'} decimalScale={2}
                                                                    value={data[year][week].vouwwandLengte.sum / 1000}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true} suffix={'m'}/>
                                                            </div>
                                                            <span className='text-muted'>
                                                                       <NumericFormat
                                                                           className={'me-1 text-nowrap'}
                                                                           decimalScale={2}
                                                                           value={data[year][week].percVouwwand.sum}
                                                                           displayType={'text'}
                                                                           thousandSeparator={true}
                                                                           suffix={'%'}/>
                                                                          <NumericFormat
                                                                              className={'me-1 text-nowrap'}
                                                                              decimalScale={2}
                                                                              value={(data[year][week].gemVouwwandLengte.sum) / 1000}
                                                                              displayType={'text'}
                                                                              thousandSeparator={true} suffix={'m'}/>
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className={'d-flex flex-column'}>
                                                            <div className={'text-nowrap'}>
                                                                <NumericFormat
                                                                    className={'me-1 text-nowrap'} decimalScale={2}
                                                                    value={data[year][week].zijlicht.sum}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true} suffix={''}/>|
                                                                <NumericFormat
                                                                    className={'ms-1 text-nowrap'} decimalScale={2}
                                                                    value={data[year][week].zijlichtLengte.sum / 1000}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true} suffix={'m'}/>
                                                            </div>
                                                            <span className='text-muted'>
                                                                       <NumericFormat
                                                                           className={'me-1 text-nowrap'}
                                                                           decimalScale={2}
                                                                           value={data[year][week].percZijlicht.sum}
                                                                           displayType={'text'}
                                                                           thousandSeparator={true}
                                                                           suffix={'%'}/>
                                                                          <NumericFormat
                                                                              className={'me-1 text-nowrap'}
                                                                              decimalScale={2}
                                                                              value={(data[year][week].gemZijlichtLengte.sum) / 1000}
                                                                              displayType={'text'}
                                                                              thousandSeparator={true} suffix={'m'}/>
                                                            </span>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className={'d-flex flex-column'}>
                                                            <NumericFormat
                                                                className={'me-1 text-nowrap'} decimalScale={2}
                                                                value={data[year][week].neuten.sum}
                                                                displayType={'text'}
                                                                thousandSeparator={true} suffix={''}/>
                                                            <span className='text-muted'>
                                                                          <NumericFormat
                                                                              className={'me-1 text-nowrap'}
                                                                              decimalScale={2}
                                                                              value={data[year][week].gemAantalNeutPerDorpel.sum}
                                                                              displayType={'text'}
                                                                              thousandSeparator={true} suffix={''}/>
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className={'d-flex flex-column'}>
                                                            <NumericFormat
                                                                className={'me-1 text-nowrap'} decimalScale={2}
                                                                value={data[year][week].voormontage.sum}
                                                                displayType={'text'}
                                                                thousandSeparator={true} suffix={''}/>
                                                            <span className='text-muted'>
                                                                          <NumericFormat
                                                                              className={'me-1 text-nowrap'}
                                                                              decimalScale={2}
                                                                              value={data[year][week].percVoormontage.sum}
                                                                              displayType={'text'}
                                                                              thousandSeparator={true} suffix={'%'}/>
                                                            </span>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className={'d-flex flex-column'}>
                                                            <NumericFormat
                                                                className={'me-1 text-nowrap'} decimalScale={2}
                                                                value={data[year][week].namontage.sum}
                                                                displayType={'text'}
                                                                thousandSeparator={true} suffix={''}/>
                                                            <span className='text-muted'>
                                                                          <NumericFormat
                                                                              className={'me-1 text-nowrap'}
                                                                              decimalScale={2}
                                                                              value={data[year][week].percNamontage.sum}
                                                                              displayType={'text'}
                                                                              thousandSeparator={true} suffix={'%'}/>
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className={'d-flex flex-column'}>
                                                            <NumericFormat
                                                                className={'me-1 text-nowrap'} decimalScale={2}
                                                                value={data[year][week].inpakken.sum}
                                                                displayType={'text'}
                                                                thousandSeparator={true} suffix={''}/>
                                                            <span className='text-muted'>
                                                                          <NumericFormat
                                                                              className={'me-1 text-nowrap'}
                                                                              decimalScale={2}
                                                                              value={data[year][week].percInpakken.sum}
                                                                              displayType={'text'}
                                                                              thousandSeparator={true} suffix={'%'}/>
                                                            </span>
                                                        </div>
                                                    </td>


                                                    <td>{data[year][week].kopisolatoren.sum}</td>
                                                    <td>{data[year][week].manchettes.sum}</td>
                                                    <td>
                                                        <div className={'d-flex flex-column'}>
                                                            <NumericFormat
                                                                className={'me-1 text-nowrap'} decimalScale={2}
                                                                value={data[year][week].latten.sum}
                                                                displayType={'text'}
                                                                thousandSeparator={true} suffix={''}/>
                                                            <span className='text-muted'>
                                                                          <NumericFormat
                                                                              className={'me-1 text-nowrap'}
                                                                              decimalScale={2}
                                                                              value={data[year][week].percDorpelsMetLatten.sum}
                                                                              displayType={'text'}
                                                                              title={'Percentage dorpels met latten'}
                                                                              thousandSeparator={true} suffix={'%'}/>
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <NumericFormat
                                                            className={'me-1 text-nowrap'} decimalScale={2}
                                                            value={data[year][week].glaslatten.sum}
                                                            displayType={'text'}
                                                            title={'Aantal glaslatten'}
                                                            thousandSeparator={true} suffix={''}/> |
                                                        <NumericFormat
                                                            className={'ms-1 text-nowrap'} decimalScale={2}
                                                            value={data[year][week].glasLatLengte.sum / 1000}
                                                            displayType={'text'}
                                                            title={'Lengte glaslatten'}
                                                            thousandSeparator={true} suffix={'m'}/>
                                                    </td>
                                                    <td><NumericFormat
                                                        className={'me-1 text-nowrap'} decimalScale={2}
                                                        value={data[year][week].aantalHoekstukjes.sum}
                                                        displayType={'text'}
                                                        title={'Aantal hoekstukjes (per stuk)'}
                                                        thousandSeparator={true} suffix={''}/></td>
                                                    <td>
                                                        <NumericFormat
                                                            className={'me-1 text-nowrap'} decimalScale={2}
                                                            value={data[year][week].beglazingsprofielen.sum}
                                                            displayType={'text'}
                                                            title={'Aantal beglazingsprofielen'}
                                                            thousandSeparator={true} suffix={''}/> |
                                                        <NumericFormat
                                                            className={'ms-1 text-nowrap'} decimalScale={2}
                                                            value={data[year][week].beglazingsprofielLengte.sum / 1000}
                                                            displayType={'text'}
                                                            title={'Lengte beglazingsprofielen'}
                                                            thousandSeparator={true} suffix={'m'}/></td>
                                                    <td><NumericFormat
                                                        className={'me-1 text-nowrap'} decimalScale={2}
                                                        value={data[year][week].lProfielen.sum}
                                                        displayType={'text'}
                                                        title={'Aantal LProfielen'}
                                                        thousandSeparator={true} suffix={''}/> |
                                                        <NumericFormat
                                                            className={'ms-1 text-nowrap'} decimalScale={2}
                                                            value={data[year][week].lProfielLengte.sum / 1000}
                                                            displayType={'text'}
                                                            title={'Lengte lprofielen'}
                                                            thousandSeparator={true} suffix={'m'}/></td>
                                                    <td><NumericFormat
                                                        className={'me-1 text-nowrap'} decimalScale={2}
                                                        value={data[year][week].aanslagprofielen.sum}
                                                        displayType={'text'}
                                                        title={'Aantal aanlagprofielen'}
                                                        thousandSeparator={true} suffix={''}/> |
                                                        <NumericFormat
                                                            className={'ms-1 text-nowrap'} decimalScale={2}
                                                            value={data[year][week].aanslagprofielLengte.sum / 1000}
                                                            displayType={'text'}
                                                            title={'Lengte aanlagprofielen'}
                                                            thousandSeparator={true} suffix={'m'}/></td>
                                                    <td><NumericFormat
                                                        className={'me-1 text-nowrap'} decimalScale={2}
                                                        value={data[year][week].sluitpotten.sum}
                                                        displayType={'text'}
                                                        title={'Aantal sluitpotten'}
                                                        thousandSeparator={true} suffix={''}/></td>
                                                    <td>
                                                        <NumericFormat
                                                            className={'me-1 text-nowrap'} decimalScale={2}
                                                            value={data[year][week].boorgaten.sum}
                                                            displayType={'text'}
                                                            title={'Aantal boonrgaten'}
                                                            thousandSeparator={true} suffix={''}/>
                                                    </td>
                                                    <td>
                                                        <NumericFormat
                                                            className={'me-1 text-nowrap'} decimalScale={2}
                                                            value={data[year][week].kalksponning.sum}
                                                            displayType={'text'}
                                                            title={'Aantal kaalksponningen'}
                                                            thousandSeparator={true} suffix={''}/>
                                                    </td>
                                                    <td><NumericFormat
                                                        className={'me-1 text-nowrap'} decimalScale={2}
                                                        value={data[year][week].groeven.sum}
                                                        displayType={'text'}
                                                        title={'Aantal groeven'}
                                                        thousandSeparator={true} suffix={''}/></td>
                                                    <td>
                                                        <NumericFormat
                                                            className={'me-1 text-nowrap'} decimalScale={2}
                                                            value={data[year][week].renovatiesponningen.sum}
                                                            displayType={'text'}
                                                            title={'Aantal renovatiesponningen'}
                                                            thousandSeparator={true} suffix={''}/>
                                                    </td>

                                                    <td>
                                                        <NumericFormat
                                                            className={'me-1 text-nowrap'} decimalScale={2}
                                                            value={data[year][week].aantalVakken.sum}
                                                            displayType={'text'}
                                                            title={'Aantal vakken'}
                                                            thousandSeparator={true} suffix={''}/>
                                                    </td>
                                                    <td><NumericFormat
                                                        className={'me-1 text-nowrap'} decimalScale={2}
                                                        value={data[year][week].hefschuifE.sum}
                                                        displayType={'text'}
                                                        title={'Aantal vakken met Hefschuifschema E'}
                                                        thousandSeparator={true} suffix={''}/></td>
                                                    <td><NumericFormat
                                                        className={'me-1 text-nowrap'} decimalScale={2}
                                                        value={data[year][week].hefSchuifA.sum}
                                                        displayType={'text'}
                                                        title={'Aantal vakken met Hefschuifschema A'}
                                                        thousandSeparator={true} suffix={''}/></td>
                                                    <td><NumericFormat
                                                        className={'me-1 text-nowrap'} decimalScale={2}
                                                        value={data[year][week].hefSchuifD.sum}
                                                        displayType={'text'}
                                                        title={'Aantal vakken met Hefschuifschema D'}
                                                        thousandSeparator={true} suffix={''}/></td>
                                                    <td><NumericFormat
                                                        className={'me-1 text-nowrap'} decimalScale={2}
                                                        value={data[year][week].hefSchuifC.sum}
                                                        displayType={'text'}
                                                        title={'Aantal vakken met Hefschuifschema C'}
                                                        thousandSeparator={true} suffix={''}/></td>
                                                    <td><NumericFormat
                                                        className={'me-1 text-nowrap'} decimalScale={2}
                                                        value={data[year][week].vouwwandBinnen.sum}
                                                        displayType={'text'}
                                                        title={'Aantal vakken met Vouwwand Binnen'}
                                                        thousandSeparator={true} suffix={''}/></td>
                                                    <td><NumericFormat
                                                        className={'me-1 text-nowrap'} decimalScale={2}
                                                        value={data[year][week].vouwwandBuiten.sum}
                                                        displayType={'text'}
                                                        title={'Aantal vakken met Vouwwand Buiten'}
                                                        thousandSeparator={true} suffix={''}/></td>
                                                    <td><NumericFormat
                                                        className={'me-1 text-nowrap'} decimalScale={2}
                                                        value={data[year][week].draaiKiep.sum}
                                                        displayType={'text'}
                                                        title={'Aantal vakken met draaikiep indeling'}
                                                        thousandSeparator={true} suffix={''}/></td>
                                                    <td><NumericFormat
                                                        className={'me-1 text-nowrap'} decimalScale={2}
                                                        value={data[year][week].deurBinnendraaiend.sum}
                                                        displayType={'text'}
                                                        title={'Aantal vakken met deur binnendraaiend'}
                                                        thousandSeparator={true} suffix={''}/></td>
                                                    <td><NumericFormat
                                                        className={'me-1 text-nowrap'} decimalScale={2}
                                                        value={data[year][week].deurBuitendraaiend.sum}
                                                        displayType={'text'}
                                                        title={'Aantal vakken met deurbuitendraaiend indeling'}
                                                        thousandSeparator={true} suffix={''}/></td>
                                                    <td><NumericFormat
                                                        className={'me-1 text-nowrap'} decimalScale={2}
                                                        value={data[year][week].vastglasBinnen.sum}
                                                        displayType={'text'}
                                                        title={'Aantal vakken met vast glas binnen'}
                                                        thousandSeparator={true} suffix={''}/></td>
                                                    <td><NumericFormat
                                                        className={'me-1 text-nowrap'} decimalScale={2}
                                                        value={data[year][week].vastglasBuiten.sum}
                                                        displayType={'text'}
                                                        title={'Aantal vakken met vast glas buiten'}
                                                        thousandSeparator={true} suffix={''}/></td>


                                                    <td>
                                                        <NumericFormat
                                                            className={'me-1 text-nowrap'} decimalScale={2}
                                                            value={data[year][week].slidingRailsHoogAantal.sum}
                                                            displayType={'text'}
                                                            title={'Aantal hoge rails'}
                                                            thousandSeparator={true} suffix={''}/> |
                                                        <NumericFormat
                                                            className={'ms-1 text-nowrap'} decimalScale={2}
                                                            value={data[year][week].slidingRailsHoogLengte.sum / 1000}
                                                            displayType={'text'}
                                                            title={'Lengte hoge rails'}
                                                            thousandSeparator={true} suffix={'m'}/>
                                                    </td>
                                                    <td>
                                                        <NumericFormat
                                                            className={'me-1 text-nowrap'} decimalScale={2}
                                                            value={data[year][week].slidingRailsLaagAantal.sum}
                                                            displayType={'text'}
                                                            title={'Aantal lage rails'}
                                                            thousandSeparator={true} suffix={''}/> |
                                                        <NumericFormat
                                                            className={'ms-1 text-nowrap'} decimalScale={2}
                                                            value={data[year][week].slidingRailsLaagLengte.sum / 1000}
                                                            displayType={'text'}
                                                            title={'Lengte lage rails'}
                                                            thousandSeparator={true} suffix={'m'}/>
                                                    </td>
                                                    <td>
                                                        <NumericFormat
                                                            className={'me-1 text-nowrap'} decimalScale={2}
                                                            value={data[year][week].fixedDoorBars.sum}
                                                            displayType={'text'}
                                                            title={'Aantal latten vaste deur'}
                                                            thousandSeparator={true} suffix={''}/> |
                                                        <NumericFormat
                                                            className={'ms-1 text-nowrap'} decimalScale={2}
                                                            value={data[year][week].fixedDoorBarLengte.sum / 1000}
                                                            displayType={'text'}
                                                            title={'Lengte latten vaste deur'}
                                                            thousandSeparator={true} suffix={'m'}/>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                        </tbody>
                                    </EkoTable>
                                </EkoCardBody>
                            </EkoCard>
                        </div>
                    ))}
                </div>
                <div className='col col-lg-4 mb-md-5 mb-xl-10'>
                    <div className={'mb-5'}>
                        <SillStatsTile data={data} measure={measure}/>
                    </div>
                    <div className={'mb-5'}>
                        <SillStatsBySillNameTile title={'Dorpels per type ' + new Date().getFullYear()} startDate={ new Date(new Date().getFullYear(), 0, 1)} endDate={ new Date(new Date().getFullYear(), 12, 31)}/>
                        <SillStatsBySillNameTile title={'Dorpels per type ' + (new Date().getFullYear()-1).toString()} startDate={ new Date(new Date().getFullYear()-1, 0, 1)} endDate={ new Date(new Date().getFullYear()-1, 12, 31)}/>
                    </div>
                    <div className={'mb-5'}>
                        <div className="card mb-5 mb-xl-8">
                            <div className="card-body p-0">
                                <div className="px-9 py-7 card-rounded w-100 bg-info text-white">
                                    <div className="d-flex flex-stack mb-5">
                                        <h3 className=" text-white fw-bold fs-3">Verdeling dorpel types</h3>
                                    </div>
                                    <Doughnut options={options} data={graphData}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


type MeasureTHProps = {
    measure: Measure;
    setMeasure: (measure: Measure) => void;
    measureValue: Measure;
    children: React.ReactNode;
};

const MeasureTH: React.FC<MeasureTHProps> = ({measure, setMeasure, measureValue, children}) => {
    return (
        <th onClick={() => setMeasure(measureValue)}
            style={{cursor: 'pointer'}}
            className={`${measure === measureValue ? 'bg-light-primary' : ''}`}>
            {children}
        </th>
    );
};

type MeasureDivProps = {
    measure: Measure;
    setMeasure: (measure: Measure) => void;
    measureValue: Measure;
    children: React.ReactNode;
};

const MeasureDiv: React.FC<MeasureDivProps> = ({measure, setMeasure, measureValue, children}) => {
    return (
        <span onClick={() => setMeasure(measureValue)}
              style={{cursor: 'pointer'}}
              className={`${measure === measureValue ? 'bg-light-primary' : ''} text-nowrap`}>
            {children}
        </span>
    );
};