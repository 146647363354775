import React, {FC} from 'react';
import ApiClient from '../../../modules/api-client/ApiClient';
import {StopProfilePicker} from '../../../backoffice/modules/pim/stopprofile/StopProfilePicker';
import {PrefValueRow} from './PrefValueRow';

export const CustomerPreferencesDeurBinnendraaiend: FC = () => {
	const queryFn = () => ApiClient.Preferences.Preferences.getStopProfiles().then((res) => res.data);

	return (
		<>
			<PrefValueRow
				label={'Standaard aanslagprofiel'}
				field={{
					name: 'stopProfileId',
					component: StopProfilePicker({
						required: false,
						multi: false,
						queryFn: queryFn,
					}),
				}}
			/>
		</>
	);
};
