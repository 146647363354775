import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import {
    PutAcceptOrderRepresentation,
} from "../../../../../modules/api-client/generated";
import { useEkosietMutation } from "../../../../../shared/hooks/useEkosietMutation";
import { NotFound, ValidationFailed } from "../../../../../modules/api-client/Responses";

export const usePutOrderAccepted = (id: string) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<void, NotFound | ValidationFailed, PutAcceptOrderRepresentation>(
        async (put: PutAcceptOrderRepresentation) => {
        await ApiClient.Sm.Order.smOrderIdAcceptPut(id, undefined, put);
    },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['smOrderIdGet', id]);
                queryClient.invalidateQueries(['OrderActionLog', id]);

                toast.success("Order geaccepteerd");
            },
            onError: () => {
                toast.error("Order accepteren mislukt!");
            }
        }
    );
};
