import {format, isValid} from "date-fns";
import {Locale} from "date-fns";
import {enUS, nl} from "date-fns/locale";
import {DateTime} from "luxon";

export const getLocale = (code?: string): Locale => {
    switch (code) {
        case "en":
            return enUS;
        default:
            return nl;
    }
};

export const formatDate = (value?: Date | string, dateFormat = "dd-MM-yyyy") => {
    if (!value) {
        return "";
    }

    const maybeParsed = new Date(value);

    if (!isValid(maybeParsed)) {
        return "Ongeldige datum";
    } else {
        return format(
            maybeParsed,
            dateFormat,
            {locale: getLocale()}
        );
    }
};
export const dateToString = (value?: Date | string, doptions?: Intl.DateTimeFormatOptions) => {
    if (!value) {
        return "";
    }

    const maybeParsed = new Date(value);
    const baseOptions: Intl.DateTimeFormatOptions = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC'
    };
    const options = {...baseOptions, ...doptions}
    if (!isValid(maybeParsed)) {
        return "Ongeldige datum";
    } else {
        return maybeParsed.toLocaleDateString('nl', options);
    }
};

export const weekNumber = (value?: string, dateFormat = "yyyy-MM-dd") => {
    if (!value) {
        return 0;
    }
    return DateTime.fromFormat(value,dateFormat).weekNumber;
};

export const weekDayColor: { [key: string]: string } =
{
    1: 'info',
    2: 'success',
    3: 'orange',
    4: 'danger',
    5: 'warning',
    6: 'light',
    7: 'secondary'
}

export const isFutureDate = (year: number, weekNumber: number) => {
    // Create a DateTime object for the start of the given week
    const date = DateTime.fromObject({ weekYear: year, weekNumber, weekday: 1 });

    // Check if the date is in the future
    return date > DateTime.local();
}

type DateFormatProps = {
    isoDateString: string;
};

export const DateFromIso: React.FC<DateFormatProps> = ({ isoDateString }) => {

    const formattedDate = DateTime.fromISO(isoDateString).toLocal().toFormat('dd-MM-yyyy');
    return <>{formattedDate}</>;
};
