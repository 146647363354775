import React, {useState} from 'react';
import { useQuery } from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import { WeeklySalesStatsRow } from '../../../../modules/api-client/generated';
import Loading from '../../../../shared/components/Loading';
import Euro from "../../../../shared/components/Euro";
import { NumericFormat } from "react-number-format";
import { KTSVG } from "../../../../shared/components/KTSVG";
import { Line } from "react-chartjs-2";
import { StatsLineChartOptions } from "../../../../shared/components/Chart";
import {Mode} from "../../mgt/pages/MgtSillStats";
import GetYearColor from "../../../../shared/components/GetYearColor";
import {calculateTrendline} from "../../../../shared/utils/trendline";

type Props = {
    className: string
    description: string
    color: string
    img: string
}
export const YTDStatsTile: React.FC<Props> = ({ className, description, color, img }) => {
    const [currentMode, setCurrentMode] = useState<Mode>('ytd' as Mode);

    const { isInitialLoading, data } = useQuery<{ [key: string]: { [key: string]: WeeklySalesStatsRow; }; }>(
        ['weeklySalesStats'],
        () => ApiClient.Sm.Report.weeklySalesStats().then((res) => res.data));

    if (!data) return <Loading />

    if (isInitialLoading) return <Loading />

    const previousYear = (year: string) => (parseInt(year) - 1).toString();
    const allWeeks = Array.from({ length: 53 }, (_, i) => (i + 1).toString());
    let yearTargets: { [key: string]: number } = {
        2024: 6000000,
        2025: 7950000,
        2026: 7950000,
        2027: 7950000,
        2028: 7950000,
        2029: 7950000,
        2030: 7950000
    };
    return (
        <>
            {Object.keys(data).reverse().map((year) => {
                return (
                    <>
                        {Object.keys(data[year]).reverse().filter((v, i) => {
                            return i === 0
                        }).map((week) => {
                            const prevYear = previousYear(year);
                            const yearData:any = {
                                labels: allWeeks,
                                datasets: [
                                    {
                                        tension: 0.3,
                                        label: year,
                                        borderColor: '#ffffff',
                                        borderWidth: 2,
                                        fill: false,
                                        pointStyle: 'circle',
                                        backgroundColor: '#ffffff',
                                        pointHoverBackgroundColor: '#093869',
                                        pointRadius: 3,
                                        pointHoverRadius: 5,
                                        data: allWeeks.map((week) => currentMode === 'ytd' ? data[year][week]?.omzetYTD || null : data[year][week]?.omzet || null),
                                    },
                                    {
                                        tension: 0.3,
                                        label: prevYear,
                                        borderColor: '#cccccc',
                                        borderWidth: 1,
                                        fill: false,
                                        pointStyle: 'circle',
                                        backgroundColor: '#cccccc',
                                        pointHoverBackgroundColor: '#093869',
                                        pointRadius: 0,
                                        pointHoverRadius: 5,
                                        data: allWeeks.map((week) => currentMode === 'ytd' ? data[prevYear]?.[week]?.omzetYTD || null : data[prevYear]?.[week]?.omzet || null),
                                    },
                                    {
                                        tension: 0.3,
                                        label: 'Trend',
                                        borderColor: '#ffffff',
                                        borderWidth: 1,
                                        fill: false,
                                        pointStyle: 'circle',
                                        backgroundColor: '#ffffff',
                                        pointHoverBackgroundColor: '#093869',
                                        pointRadius: 0,
                                        pointHoverRadius: 0,
                                        data: calculateTrendline(allWeeks.map((week) => currentMode === 'ytd' ? data[year]?.[week]?.omzetYTD || null : data[year]?.[week]?.omzet || null))
                                    },
                                    {
                                        tension: 0.3,
                                        label: 'Doel',
                                        borderColor: '#ff9900',
                                        borderWidth: 2,
                                        fill: false,
                                        pointStyle: 'line',
                                        backgroundColor: '#ff9900',
                                        pointHoverBackgroundColor: '#ff0000',
                                        pointRadius: 0,
                                        pointHoverRadius: 4,
                                        data: allWeeks.map((week) => currentMode === 'ytd' ? +week * (yearTargets[year] / allWeeks.length) || null : yearTargets[year] / allWeeks.length  || null)
                                    },
                                ],
                            };
                            return (
                                <div key={year} className="card mb-5 mb-xl-8">
                                    <div className="card-body p-0">

                                        <div className="px-9 pt-7 card-rounded h-500px w-100 bg-success">
                                            <div className="d-flex flex-stack"><h3
                                                className="m-0 text-white fw-bold fs-3">Jaaroverzicht {year}</h3>
                                                <div className='form-check form-switch'>
                                                    <input className='form-check-input' type='checkbox' role='switch'
                                                           onChange={() => setCurrentMode((currentMode === 'ytd' ? 'sum' : 'ytd') as Mode)}
                                                           checked={currentMode === 'ytd'}
                                                           id='toggleMode'/>
                                                    <label className='form-check-label text-white'
                                                           htmlFor='toggleMode'>YTD</label>
                                                </div>
                                            </div>

                                            <Line options={StatsLineChartOptions} data={yearData}/>

                                            <div className="d-flex text-center flex-column text-white pt-8"><span
                                                className="fw-semibold fs-7">Intake</span><span
                                                className="fw-bold fs-2x pt-1">	<Euro
                                                value={ currentMode === 'ytd' ? data[year][week]?.omzetYTD || 0 : data[year][week]?.omzet || 0}/></span>
                                                <span
                                                    className="fs-4 fw-bold">	{prevYear}:  <Euro
                                                    value={ currentMode === 'ytd' ? data[prevYear]?.[week]?.omzetYTD || 0 : data[prevYear]?.[week]?.omzet || 0}/></span>
                                            </div>
                                        </div>
                                        <div
                                            className="shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body"
                                            style={{marginTop: '-25px'}}>
                                            <div className="d-flex align-items-center mb-6">
                                                <div className="symbol symbol-45px w-40px me-5"><span
                                                    className="symbol-label bg-lighten">
                                                    <KTSVG path={'/media/icons/duotune/general/gen005.svg'} className='svg-icon-2' />
                                                </span>
                                                </div>
                                                <div className="d-flex align-items-center flex-wrap w-100">
                                                    <div className="mb-1 pe-3 flex-grow-1"><a href="#"
                                                        className="fs-5 text-gray-800 text-hover-primary fw-bold">Dorpels</a>
                                                        <div className="text-gray-400 fw-semibold fs-7">
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div
                                                            className="fw-bold fs-5 text-gray-800 pe-1"><Euro
                                                                value={data[year][week].omzetDorpelsYTD} /></div>
                                                        <i className="ki-duotone ki-arrow-up fs-5 text-success ms-1"><span
                                                            className="path1"></span><span className="path2"></span></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mb-6">
                                                <div className="symbol symbol-45px w-40px me-5"><span
                                                    className="symbol-label bg-lighten">
                                                    <KTSVG path={'/media/icons/duotune/general/gen005.svg'} className='svg-icon-2' />
                                                </span>
                                                </div>
                                                <div className="d-flex align-items-center flex-wrap w-100">
                                                    <div className="mb-1 pe-3 flex-grow-1"><a href="#"
                                                        className="fs-5 text-gray-800 text-hover-primary fw-bold">Producten</a>
                                                        <div className="text-gray-400 fw-semibold fs-7">
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div
                                                            className="fw-bold fs-5 text-gray-800 pe-1"><Euro
                                                                value={data[year][week].omzetProductenYTD} /></div>
                                                        <i className="ki-duotone ki-arrow-up fs-5 text-success ms-1"><span
                                                            className="path1"></span><span className="path2"></span></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mb-6">
                                                <div className="symbol symbol-45px w-40px me-5"><span
                                                    className="symbol-label bg-lighten">
                                                    <KTSVG path={'/media/icons/duotune/general/gen005.svg'} className='svg-icon-2' />
                                                </span>
                                                </div>
                                                <div className="d-flex align-items-center flex-wrap w-100">
                                                    <div className="mb-1 pe-3 flex-grow-1"><a href="#"
                                                        className="fs-5 text-gray-800 text-hover-primary fw-bold">Transport</a>
                                                        <div className="text-gray-400 fw-semibold fs-7">
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div
                                                            className="fw-bold fs-5 text-gray-800 pe-1"><Euro
                                                                value={data[year][week].omzetTransportYTD} /></div>
                                                        <i className="ki-duotone ki-arrow-up fs-5 text-success ms-1"><span
                                                            className="path1"></span><span className="path2"></span></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mb-6">
                                                <div className="symbol symbol-45px w-40px me-5"><span
                                                    className="symbol-label bg-lighten">
                                                    <KTSVG path={'/media/icons/duotune/general/gen005.svg'} className='svg-icon-2' />
                                                </span>
                                                </div>
                                                <div className="d-flex align-items-center flex-wrap w-100">
                                                    <div className="mb-1 pe-3 flex-grow-1"><a href="#"
                                                        className="fs-5 text-gray-800 text-hover-primary fw-bold">Overig</a>
                                                        <div className="text-gray-400 fw-semibold fs-7">
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div
                                                            className="fw-bold fs-5 text-gray-800 pe-1"><Euro
                                                                value={data[year][week].omzetOverigYTD} /></div>
                                                        <i className="ki-duotone ki-arrow-up fs-5 text-success ms-1"><span
                                                            className="path1"></span><span className="path2"></span></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mb-6">
                                                <div className="symbol symbol-45px w-40px me-5"><span
                                                    className="symbol-label bg-lighten">
                                                    <KTSVG path={'/media/icons/duotune/general/gen005.svg'} className='svg-icon-2' />
                                                </span>
                                                </div>
                                                <div className="d-flex align-items-center flex-wrap w-100">
                                                    <div className="mb-1 pe-3 flex-grow-1"><a href="#"
                                                        className="fs-5 text-gray-800 text-hover-primary fw-bold">Orders</a>
                                                        <div className="text-gray-400 fw-semibold fs-7">
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div
                                                            className="fw-bold fs-5 text-gray-800 pe-1">{data[year][week].ordersYTD}</div>
                                                        <i className="ki-duotone ki-arrow-up fs-5 text-success ms-1"><span
                                                            className="path1"></span><span className="path2"></span></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mb-6">
                                                <div className="symbol symbol-45px w-40px me-5"><span
                                                    className="symbol-label bg-lighten">
                                                    <KTSVG path={'/media/icons/duotune/finance/fin001.svg'} className='svg-icon-2' />
                                                </span>
                                                </div>
                                                <div className="d-flex align-items-center flex-wrap w-100">
                                                    <div className="mb-1 pe-3 flex-grow-1"><a href="#"
                                                        className="fs-5 text-gray-800 text-hover-primary fw-bold">Dorpels</a>
                                                        <div className="text-gray-400 fw-semibold fs-7">
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div
                                                            className="fw-bold fs-5 text-gray-800 pe-1">{data[year][week].dorpelsYTD}</div>
                                                        <i className="ki-duotone ki-arrow-down fs-5 text-danger ms-1"><span
                                                            className="path1"></span><span className="path2"></span></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mb-6">
                                                <div className="symbol symbol-45px w-40px me-5"><span
                                                    className="symbol-label bg-lighten">
                                                    <KTSVG path={'/media/icons/duotune/general/gen056.svg'} className='svg-icon-2' />
                                                </span>
                                                </div>
                                                <div className="d-flex align-items-center flex-wrap w-100">
                                                    <div className="mb-1 pe-3 flex-grow-1"><a href="#"
                                                        className="fs-5 text-gray-800 text-hover-primary fw-bold">Neuten</a>
                                                        <div
                                                            className="text-gray-400 fw-semibold fs-7"> Gem. <NumericFormat
                                                                className={'me-1 text-nowrap'} decimalScale={2}
                                                                value={data[year][week].avgNeutenYTD} displayType={'text'}
                                                                thousandSeparator={true} suffix={' stuks'} />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="fw-bold fs-5 text-gray-800 pe-1"><NumericFormat
                                                            className={'me-1 text-nowrap'} decimalScale={0}
                                                            value={data[year][week].neutenYTD} displayType={'text'}
                                                            thousandSeparator={true} suffix={' stuks'} /></div>
                                                        <i className="ki-duotone ki-arrow-down fs-5 text-danger ms-1"><span
                                                            className="path1"></span><span className="path2"></span></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mb-6">
                                                <div className="symbol symbol-45px w-40px me-5"><span
                                                    className="symbol-label bg-lighten">
                                                    <KTSVG path={'/media/icons/duotune/abstract/abs002.svg'} className='svg-icon-2' />
                                                </span>
                                                </div>
                                                <div className="d-flex align-items-center flex-wrap w-100">
                                                    <div className="mb-1 pe-3 flex-grow-1"><a href="#"
                                                        className="fs-5 text-gray-800 text-hover-primary fw-bold">Lengte</a>
                                                        <div
                                                            className="text-gray-400 fw-semibold fs-7"> Gem. <NumericFormat
                                                                className={'me-1 text-nowrap'} decimalScale={2}
                                                                value={data[year][week].avgLengteYTD} displayType={'text'}
                                                                thousandSeparator={true} suffix={' m'} />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="fw-bold fs-5 text-gray-800 pe-1"><NumericFormat
                                                            className={'me-1 text-nowrap'} decimalScale={2}
                                                            value={data[year][week].meterYTD} displayType={'text'}
                                                            thousandSeparator={true} suffix={' m'} /></div>
                                                        <i className="ki-duotone ki-arrow-down fs-5 text-danger ms-1"><span
                                                            className="path1"></span><span className="path2"></span></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )
                        })
                        }

                    </>
                )

            })}
        </>
    );
};