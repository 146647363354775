/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {CustomerSillStats, CustomerSillStats as x} from "../../../../modules/api-client/generated";
import ApiClient from "../../../../modules/api-client/ApiClient";
import Loading from "../../../../shared/components/Loading";
import {CustomerSalesStats} from "./CustomerSalesStats";
import {CustomerSillsStats} from "./CustomerSillsStats";
import {CustomerExpeditionStats} from "./CustomerExpeditionStats";
import {DateTime} from "luxon";
import {SillStatsBySillNameTile} from "../../sm/components/SillStatsBySillNameTile";
export function CustomerStats() {
    const {id} = useParams<{ id: string }>();
    const [year, setYearState] = useState<number|undefined>(new Date().getFullYear());
    const [startDate, setStartDate] = useState<Date>(new Date(new Date().getFullYear(), 0, 1));
    const [endDate, setEndDate] = useState<Date>(new Date(new Date().getFullYear(), 11, 31));

    const setYear = (newYear: number|undefined) => {
        setYearState(newYear);
        if(newYear === undefined) {
            setStartDate(new Date(2023,0,1));
            setEndDate(new Date(new Date().getFullYear(), 11,31));
            return;
        }
        setStartDate(new Date(newYear, 0, 1));
        setEndDate(new Date(newYear, 11,31));
    }

    const {isInitialLoading, data, isLoading} = useQuery<CustomerSillStats, TypeError, CustomerSillStats>(
        ['mgtCustomerSillStats',year],
        () => ApiClient.Crm.Reporting.customer(id, year).then((res) => res.data));
    if (!data) return <Loading/>
    if (isLoading) return <Loading/>

    const currentYear = new Date().getFullYear();
    const years = [];
    for(let i = 2023; i <= currentYear; i++) {
        years.push(i);
    }
    const customerIds = [data.id];

    return (
        <>
            <div className={'mb-4'}>
                {years.map((year) => (
                    <a key={year} href="#" className={`  me-3 ${data.jaar === year ? 'fw-bolder fs-2' : 'fs-4'}`} onClick={(e) => {
                        e.preventDefault();
                        setYear(year);
                    }}>{year}</a>
                ))}
                <a href="#" className={`  me-3 ${year === undefined ? 'fw-bolder fs-2' : 'fs-4'}`} onClick={(e) => {
                    e.preventDefault();
                    setYear(undefined);
                }}>Totaal</a>

            </div>
            <div className='row'>
                <div className='col-xs-12 col-xl-6 col-xxl-4 mb-md-5'>
                    <CustomerSalesStats data={data} title={'Sales'}/>
                    <SillStatsBySillNameTile startDate={startDate} endDate={endDate} customerIds={customerIds}/>
                </div>
                <div className='col-xs-11 col-xl-6 col-xxl-4 mb-md-5'>
                    <CustomerSillsStats data={data}  title={'Dorpels'}/>
                </div>
                <div className='col-xs-10 col-xl-6 col-xxl-4 mb-md-5'>
                    <CustomerExpeditionStats data={data}  title={'Leveringen'}/>
                </div>
            </div>
        </>
    )
}
