export function calculateTrendline(data: any) {
    let sumX = 0;
    let sumY = 0;
    let sumXY = 0;
    let sumXX = 0;
    let count = 0;

    // Calculate sums
    for (let i = 0; i < data.length; i++) {
        if (data[i] === null) continue;
        let x = i;
        let y = data[i];
        sumX += x;
        sumY += y;
        sumXX += x * x;
        sumXY += x * y;
        count++;
    }

    // Calculate m and b for the formula y = mx + b
    let m = (count * sumXY - sumX * sumY) / (count * sumXX - sumX * sumX);
    let b = (sumY / count) - (m * sumX) / count;

    // We then return the x and y data points according to our fit
    let resultValuesXandY = [];
    for (let i = 0; i < data.length; i++) {
        let x = i;
        let y = m * x + b;
        resultValuesXandY.push({x, y});
    }

    return resultValuesXandY;
}