import {useQueryClient} from "@tanstack/react-query";
import {toast} from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import {    
    PutOrderExpeditionRepresentation
} from "../../../../../modules/api-client/generated";
import {useEkosietMutation} from "../../../../../shared/hooks/useEkosietMutation";
import {NotFound, ValidationFailed} from "../../../../../modules/api-client/Responses";

export const usePutExpedition = (id: string) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<void, NotFound | ValidationFailed, PutOrderExpeditionRepresentation>(
        async (model: PutOrderExpeditionRepresentation) => {
            await ApiClient.Sm.Order.smOrderIdExpeditionPut(id, undefined, model);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['smOrderIdGet', id]);
            },
            onError: () => {
                toast.error("Opslaan order mislukt!");
            }
        }
    );
};
