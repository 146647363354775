import {useQueryClient} from "@tanstack/react-query";
import {toast} from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import {    
    PutExpeditionDateRepresentation
} from "../../../../../modules/api-client/generated";
import {useEkosietMutation} from "../../../../../shared/hooks/useEkosietMutation";
import {NotFound, ValidationFailed} from "../../../../../modules/api-client/Responses";

export const usePutExpeditionDate = (id: string) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<void, NotFound | ValidationFailed, PutExpeditionDateRepresentation>(
        async (
            model: PutExpeditionDateRepresentation) => {
            await ApiClient.Sm.Order.smOrderIdExpeditionPreferredDatePut(id, undefined, model);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['smOrderIdGet', id]);
                queryClient.invalidateQueries(['OrderActionLog', id]);
                toast.success("Expeditiedatum aangepast.");

            },
            onError: () => {
                toast.error("Expeditiedatum wijzigen mislukt!");
            }
        }
    );
};
