import {useQueryClient} from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import * as api from "../../../../modules/api-client/generated";
import {NotFound, ValidationFailed} from "../../../../modules/api-client/Responses";
import {useEkosietMutation} from "../../../../shared/hooks/useEkosietMutation";
import { DownloadFormValues } from "./DownloadUpsert";

export const useDownloadUpsertMutation = (id?: string | undefined) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<api.ContentDetails, NotFound | ValidationFailed, DownloadFormValues>(async (values) => {
        if (id) {
            const response = await ApiClient.Homepage.changeDownload(id, values.imageId, values.downloadId);
            return response.data;
        }

        const createDownload = {
            code: values.code,
            downloadId: values.downloadId ?? '',
            imageId: values.imageId ?? '',
        };

        const response = await ApiClient.Homepage.addDownload(undefined, createDownload);
        return response.data;
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['homepageDownloadUpsertGet', id]).then();
            }
        }
    );
};