import React, {FC} from 'react';
import {FieldAttributes} from 'formik';
import {EkoFormRow} from '../EkoFormRow';
import {PrefValueField} from './PrefValueField';

export const PrefValueRow: FC<{label: string; field: FieldAttributes<any>}> = (props) => {
	return (
		<EkoFormRow>
			<div className="col-4">{props.label}</div>
			<div className={'col'}>
				<PrefValueField field={props.field} />
			</div>
		</EkoFormRow>
	);
};