import {useQueryClient} from "@tanstack/react-query";
import {toast} from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import {    
    PutOrderFinancialRepresentation
} from "../../../../../modules/api-client/generated";
import {useEkosietMutation} from "../../../../../shared/hooks/useEkosietMutation";
import {NotFound, ValidationFailed} from "../../../../../modules/api-client/Responses";

export const usePutFinancial = (id: string) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<void, NotFound | ValidationFailed, PutOrderFinancialRepresentation>(
        async (model: PutOrderFinancialRepresentation) => {
            await ApiClient.Sm.Order.smOrderIdFinancialPut(id, undefined, model);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['smOrderIdGet', id]);
                queryClient.invalidateQueries(['OrderActionLog', id]);

            },
            onError: () => {
                toast.error("Opslaan order mislukt!");
            }
        }
    );
};
