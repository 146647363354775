import React, {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {
    ExpeditionForProductionItemRepresentation,
    ExpeditionsForProductionItemRow
} from "../../../../../modules/api-client/generated";
import ApiClient from "../../../../../modules/api-client/ApiClient";
import Loading from "../../../../../shared/components/Loading";
import OrdersWithSillSummaryOverview from "./OrdersWithSillSummaryOverview";

interface DeliverOrdersProps {
    orderId: string,
    updateExpeditionStatusToDelivered: (orderIds: string[]) => void
}
const DeliverOrdersOverview: React.FC<DeliverOrdersProps> = ({ orderId, updateExpeditionStatusToDelivered}) => {
    const [checkedOrders, setCheckedOrders] = useState<{ [key: string]: boolean }>({});
    // const [btnClicked, setBtnClicked] = useState<boolean>(false);

    const {
        isInitialLoading,
        data: expeditionData,
        isError
    } = useQuery<ExpeditionForProductionItemRepresentation>(['emExpeditionOrderIdGet', orderId], () =>
        ApiClient.Em.Expedition.emExpeditionOrderIdGet(orderId!).then((res) => res.data));

    useEffect(() => {
        if (expeditionData && expeditionData.relatedExpeditions) {
            setCheckedOrders(initializeCheckedOrders(expeditionData.relatedExpeditions));
        }
    }, [expeditionData]);

    const initializeCheckedOrders = (ordersByExpeditionDate: any) => {
        const initialCheckedOrders: { [key: string]: boolean } = {};
        Object.values(ordersByExpeditionDate).forEach((datum: any) => {
            datum.expeditions.forEach((order: ExpeditionsForProductionItemRow) => {
                initialCheckedOrders[order.orderId!] = order.expeditionStatus === "inTransit";
            });
        });
        return initialCheckedOrders;
    };

    const getSelectedOrders = () => {
        return Object.keys(checkedOrders).filter((key) => checkedOrders[key]);
    }

    if (isInitialLoading || !expeditionData) {
        return <Loading />;
    }

    if (isError) {
        return <>Fout bij het ophalen van de gegevens!</>;
    }

    return (
        <>
            <OrdersWithSillSummaryOverview
                showHeaderLine={true}
                showCheckboxes={true}
                orderIdToQuery={orderId}
                updateExpeditionStatusToDelivered={updateExpeditionStatusToDelivered}
                customer={expeditionData.expedition.customer.name}
                orderIdsToShow={getSelectedOrders()}
            />
        </>
    );
}
export default DeliverOrdersOverview
