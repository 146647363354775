import {HefSchuifInnerDoorPosition} from '../../../modules/api-client/generated';
import React from 'react';

export type HefSchuifInnerDoorPositionSelectProps = React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;

export const HefSchuifInnerDoorPositionSelect: React.FC<HefSchuifInnerDoorPositionSelectProps> = (props) => {
	const value = props.value as HefSchuifInnerDoorPosition;

	return (
		<select {...props}>
			<option value={HefSchuifInnerDoorPosition.Left}>
				<HefSchuifInnerDoorPositionText value={HefSchuifInnerDoorPosition.Left} />
			</option>
			<option value={HefSchuifInnerDoorPosition.Right}>
				<HefSchuifInnerDoorPositionText value={HefSchuifInnerDoorPosition.Right} />
			</option>
		</select>
	);
};

export const HefSchuifInnerDoorPositionText: React.FC<{value: HefSchuifInnerDoorPosition | undefined}> = ({value}) => {
	switch (value) {
		case HefSchuifInnerDoorPosition.Left: {
			return <>Links</>;
		}
		case HefSchuifInnerDoorPosition.Right: {
			return <>Rechts</>;
		}
		default:
			return <>{value}</>;
	}
};
