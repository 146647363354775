import React, { useLayoutEffect } from 'react';
import { Formik, FormikHelpers, useFormikContext } from 'formik';
import * as api from '../../../../modules/api-client/generated';
import { useNavigate, useParams } from 'react-router-dom';
import SubmitButton from '../../../../shared/components/SubmitButton';
import * as Yup from 'yup';
import FieldErrors from '../../../../shared/components/FieldErrors';
import Loading from '../../../../shared/components/Loading';
import { useQuery } from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import { useContentUpsertMutation } from './hooks';
import { DateInput } from '../../../../shared/components/date/DateInput';
import '@mdxeditor/editor/style.css'
import { BlockTypeSelect, BoldItalicUnderlineToggles, MDXEditor, UndoRedo, headingsPlugin, linkPlugin, toolbarPlugin, linkDialogPlugin, CreateLink, InsertThematicBreak, thematicBreakPlugin, ListsToggle, listsPlugin } from '@mdxeditor/editor'
import { FileUpload } from '../../../../shared/components/file-upload/FileUpload';
import { ContentTypeText } from './ContentTypeText';
import { Button } from 'react-bootstrap';

interface ContentUpsertProps {
    actionType: ActionType
}

export interface ContentFormValues {
    code: string;
    message: string;
    type: string;
    actionType: ActionType;
    datePosted?: Date;
    validFrom?: Date;
    validTo?: Date;
    imageId?: string;
    blobUri?: string;
}

export enum ActionType {
    Add,
    ChangeImage,
    ChangeMessageAndCreationDate,
    ChangeMessage,
    ActivateNotification,
}

const ContentUpsert: React.FC<ContentUpsertProps> = (props) => {
    const { id } = useParams<{ id: string }>();

    const mutation = useContentUpsertMutation(id);

    const navigate = useNavigate();

    const onSubmit = async (values: ContentFormValues, { setSubmitting }: FormikHelpers<ContentFormValues>) => {
        await mutation.mutateAsync(values, {
            onSuccess: (data: any) => {
                if (values.type.toUpperCase() === api.ContentType.NewsItem.toUpperCase() &&
                    values.actionType === ActionType.Add && data?.message?.id) {
                    navigate(`../${data.message.id}`, { relative: 'path' });
                    return;
                }
                navigate(`../`, { relative: 'path' });
            },
            onError: () => {
            },
            onSettled: () => {
                setSubmitting(false)
            }
        });
    };

    const { isInitialLoading, data: details, isError } = useQuery<api.ContentDetails>(
        ['homepageContentUpsertGet', id],
        () => ApiClient.Homepage.getContentDetails(id!).then((res) => res.data),
        {
            enabled: !!id
        });

    if (isInitialLoading) return <Loading />;
    if (isError) return (<>Error...</>);

    const initialValues: ContentFormValues = {
        code: details?.code ?? '',
        message: details?.message ?? '',
        type: details?.contentType ?? '',
        actionType: props.actionType ?? ActionType.Add,
        datePosted: details?.datePosted ? new Date(details.datePosted) : new Date(),
        validFrom: details?.validFrom ? new Date(details.validFrom) : new Date(),
        validTo: details?.validTo ? new Date(details.validTo) : new Date(),
        blobUri: details?.image?.blobUri ?? undefined,
    };

    return <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={UpsertSchema}>
        <ContentUpsertForm id={id} mutation={mutation} actionType={props.actionType} />
    </Formik>;
};

const UpsertSchema = Yup.object().shape({
    code: Yup.string()
        .min(1)
        .max(50)
        .required('Geen code ingevoerd'),

    type: Yup.string()
        .when('actionType', {
            is: ActionType.Add.toString(),
            then: Yup.string().required('Geen content type gekozen'),
        })
});

interface ContentUpsertFormProps {
    id: string | undefined;
    mutation: ReturnType<typeof useContentUpsertMutation>;
    actionType: ActionType;
}

export const ContentUpsertForm: React.FC<ContentUpsertFormProps> = (props) => {
    const {
        values,
        handleSubmit,
        handleChange,
        isSubmitting,
        errors,
        setFieldValue,
        setErrors,
    } = useFormikContext<ContentFormValues>();

    const mergeServerErrors = () => {

        if (props.mutation.serverValidationErrors) {
            setErrors({ ...errors, ...props.mutation.serverValidationErrors });
        }
    };

    const navigate = useNavigate();

    useLayoutEffect(mergeServerErrors, [props.mutation.serverValidationErrors, errors, setErrors]);

    return (<form onSubmit={handleSubmit}>
        <div className={'row'}>
            <div className='col-12 col-md-10'>
                <div className='card'>
                    <div className='card-header'>
                        <h3 className='card-title'>
                            {props.id && 'Content wijzigen'}
                            {!props.id && 'Content toevoegen'}
                        </h3>
                        <div className='card-toolbar'>
                        </div>
                    </div>

                    <div className='card-body'>
                        <div className='row mb-4'>
                            <div className='col-4'>
                                <label className='form-label'>Code</label>
                                <FieldErrors field='code' />
                            </div>
                            <div className='col-8'>
                                <input type='text' className={`form-control`}
                                    name='code' required
                                    autoComplete='off'
                                    placeholder='Unieke code voor content'
                                    onChange={handleChange}
                                    value={values.code}
                                    autoFocus
                                    disabled={props.actionType !== ActionType.Add}
                                />
                            </div>
                        </div>

                        {!(props.actionType === ActionType.ChangeImage || 
                        values.type.toUpperCase() === api.ContentType.Image.toUpperCase()) &&
                            <div className='row mb-4'>
                                <div className='col-4'>
                                    <label className='form-label'>Inhoud</label>
                                    <FieldErrors field='message' />
                                </div>
                                <div className='col-8'>
                                    <div className='border border-secondary p-0 rounded-1'>
                                        <MDXEditor
                                            contentEditableClassName="prose"
                                            markdown={values.message}
                                            onChange={val => { setFieldValue('message', val) }}
                                            plugins={[headingsPlugin(), linkPlugin(), linkDialogPlugin(), thematicBreakPlugin(), listsPlugin(), toolbarPlugin(
                                                {
                                                    toolbarContents: () => (
                                                        <>
                                                            {' '}<UndoRedo />
                                                            {' '}<BlockTypeSelect />
                                                            {' '}<BoldItalicUnderlineToggles />
                                                            {' '}<CreateLink />
                                                            {' '}<InsertThematicBreak />
                                                            {' '}<ListsToggle />
                                                        </>)
                                                })]}
                                        />
                                    </div>
                                </div>
                            </div>}

                        {(props.actionType === ActionType.ChangeImage ||
                            values.type.toUpperCase() === api.ContentType.Image.toUpperCase()
                        ) &&
                            <div className='row mb-4'>
                                <div className='col-4'>
                                    <label className='form-label'>Afbeelding</label>
                                    <FieldErrors field='image' />
                                </div>
                                <div className='col-8' style={{ minHeight: "200px" }}>
                                    <div className='position-relative'>
                                        <FileUpload 
                                            onFileUpload={(fileId) => setFieldValue('imageId', fileId)}
                                            show='drag'
                                            fileCategory={values.type === "newsItem" ? api.FileCategory.Nieuwsafbeelding : api.FileCategory.Afbeelding}
                                            sasUri={values.blobUri ?? ''}
                                            uploadType='image'
                                        />
                                    </div>
                                </div>
                            </div>}

                        {values.actionType === ActionType.Add &&
                            <div className='row mb-4'>
                                <div className='col-4'>
                                    <label className='form-label' htmlFor='type'>Type</label>
                                    <FieldErrors field='type' />
                                </div>
                                <div className='col-8'>
                                    <select id='type' className='form-select' name='type' required
                                        onChange={handleChange}
                                        value={values.type}>
                                        <option value=''>Selecteer content type</option>

                                        {Object.values(api.ContentType).map(value =>
                                            <option key={value} value={value}><ContentTypeText type={value.toLowerCase()} /></option>,
                                        )}
                                    </select>
                                </div>
                            </div>}

                        {((values.type.toUpperCase() === api.ContentType.NewsItem.toUpperCase() && values.actionType === ActionType.Add) ||
                            values.actionType === ActionType.ChangeMessageAndCreationDate) &&
                            <div className='row mb-4'>
                                <div className='col-4'>
                                    <label className='form-label'>Geplaatst op</label>
                                    <FieldErrors field='datePosted' />
                                </div>
                                <div className='col-8'>
                                    <DateInput value={values.datePosted}
                                        onChange={val => { setFieldValue('datePosted', val) }}
                                        placeholder='Geplaatst op'
                                        dateFormat='dd-MM-yyyy' />
                                </div>
                            </div>}

                        {values.type.toUpperCase() === api.ContentType.Notification.toUpperCase() &&
                            <div className='row mb-4'>
                                <div className='col-4'>
                                    <label className='form-label'>Geldig van</label>
                                    <FieldErrors field='validFrom' />
                                </div>
                                <div className='col-8'>
                                    <DateInput value={values.validFrom}
                                        onChange={val => { setFieldValue('validFrom', val) }}
                                        placeholder='Geldig van'
                                        dateFormat='dd-MM-yyyy' />
                                </div>
                            </div>}

                        {values.type.toUpperCase() === api.ContentType.Notification.toUpperCase() &&
                            <div className='row mb-4'>
                                <div className='col-4'>
                                    <label className='form-label'>Geldig tot</label>
                                    <FieldErrors field='validTo' />
                                </div>
                                <div className='col-8'>
                                    <DateInput value={values.validTo}
                                        onChange={val => { setFieldValue('validTo', val) }}
                                        placeholder='Geldig tot'
                                        dateFormat='dd-MM-yyyy' />
                                </div>
                            </div>}

                        <div className='row pt-5'>
                            <div className='offset-4 col-8 d-flex justify-content-end'>
                                <Button onClick={() => navigate(`../`, { relative: 'path' })} className='me-5'>Annuleren</Button>
                                <SubmitButton type='submit' className='btn btn-primary'
                                    isSubmitting={isSubmitting}>Opslaan</SubmitButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>);
};

export default ContentUpsert;