/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {EkoTable} from "../../../../shared/components/table/EkoTable";
import {TableHeader} from "../../../../shared/components/table/TableHeader";
import {useQuery} from "@tanstack/react-query";
import {
    ProductionItemOperations,
    ProductionItemViewRepresentationArrayODataValue
} from "../../../../modules/api-client/generated";
import {AxiosError} from "axios";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {
    ProductionItemStationStatus,
    ProductionItemStationStatusGroup,
    ProductionItemStatus
} from "../../../../shared/components/ProductionItemStatus";
import {Link} from "react-router-dom";
import {PmRoutePath} from "../PmRoutes";
import {KTSVG} from '../../../../shared/components/KTSVG';
import classNames from "classnames";
import Loading from "../../../../shared/components/Loading";
import {OrderProductionStatus} from "../../../../shared/components/ProductionItemGroupStatus";
import {formatDate} from "../../../../shared/components/date";

type Props = {
    filterQuery?: string
    sillCount: number
}
const ProductionItemTable: React.FC<Props> = ({filterQuery, sillCount}) => {

    var key = 'pmProductionItemListOdataGet';

    const {isInitialLoading, isError, data: list, error} =
        useQuery<ProductionItemViewRepresentationArrayODataValue, AxiosError, ProductionItemViewRepresentationArrayODataValue>(
            [key, filterQuery], () => {
                return ApiClient.Pm.ProductionItem.pmProductionItemListOdataGet(
                    undefined, undefined, undefined, filterQuery ?? undefined, undefined, undefined, undefined)
                    .then(d => d.data);
            }, {
                refetchInterval: 3000
            }
        )

    if (isInitialLoading) {
        return <Loading/>;
    }

    if (isError) {
        return <>
            {error?.message}
            Fout bij het ophalen van de productie items!
        </>;
    }

    if (!list || !list.value) return <></>;

    return (
        <>
            <div className="table-responsive">
                <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5">

                    <tbody className="fw-semibold text-gray-600">
                    <tr>
                        <td className="text-muted">
                            <div className="d-flex align-items-center">
                                <KTSVG path={'/media/icons/duotune/general/gen025.svg'}
                                       className='svg-icon-2 me-2'/>

                                Status
                            </div>
                        </td>

                        <td className={classNames(
                            list.value.length !== sillCount
                                ? "text-danger"
                                : "text-success",
                            "fw-bold text-end",
                        )}>
                            <OrderProductionStatus productionItems={list.value}/>

                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div className="table-responsive">
                <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5">

                    <tbody className="fw-semibold text-gray-600">
                    <tr>
                        <td className="text-muted">
                            <div className="d-flex align-items-center">
                                <KTSVG path={'/media/icons/duotune/general/gen025.svg'}
                                       className='svg-icon-2 me-2'/>

                                Aantal productieitems<br/>
                            </div>
                        </td>

                        <td className={classNames(
                            list.value.length !== sillCount
                                ? "text-danger"
                                : "text-success",
                            "fw-bold text-end",
                        )}>
                            {list.value.length}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <EkoTable className={'card-xxl-stretch mb-5 mb-xxl-8'}>
                <TableHeader>
                    <th className='min-w-40px'>Item</th>
                    <th className='min-w-40px'>Geplande productie</th>
                    <th className='min-w-140px'>Status</th>
                </TableHeader>
                <tbody>
                {list.value && list.value.map((pi, index) =>
                    <tr key={pi.id}>
                        <td>
                            <div>
                                <Link to={PmRoutePath.link(PmRoutePath.productionItemLink(pi.id!))}
                                      className='text-hover-primary fs-6'>
                                    {pi.catalogItemTitle}
                                </Link>
                            </div>
                            <div>
                                <Link
                                    to={PmRoutePath.link(PmRoutePath.productionItemGroupLink(pi.code))}
                                    className='text-hover-primary fs-6'>
                                    {pi.code}
                                    {pi.sequence}
                                </Link>
                            </div>
                        </td>
                        <td>{formatDate(pi.plannedProductionDate)}</td>
                        <td>
                            <div>
                                <ProductionItemStatus status={pi.status}/>
                            </div>
                            <div>
                                {/*<ProductionItemStationStatusGroup pi={pi}/>*/}
                                <ProductionItemStationStatus
                                    title={ProductionItemOperations.NeutenGemaakt}
                                    status={pi.neutenGemaakt}/>
                                <ProductionItemStationStatus
                                    title={ProductionItemOperations.LattenGemaakt}
                                    status={pi.lattenGemaakt}/>
                                <ProductionItemStationStatus
                                    title={ProductionItemOperations.DorpelProfielGezaagd}
                                    status={pi.dorpelProfielGezaagd}/>
                                <ProductionItemStationStatus
                                    title={ProductionItemOperations.DorpelBewerkt}
                                    status={pi.dorpelBewerkt}/>
                                <ProductionItemStationStatus
                                    title={ProductionItemOperations.NeutenGemonteerd}
                                    status={pi.neutenGemonteerd}/>
                                <ProductionItemStationStatus
                                    title={ProductionItemOperations.SluitpottenGemonteerd}
                                    status={pi.sluitpottenGemonteerd}/>
                                <ProductionItemStationStatus
                                    title={ProductionItemOperations.LattenGemonteerd}
                                    status={pi.lattenGemonteerd}/>
                                <ProductionItemStationStatus
                                    title={ProductionItemOperations.KopisolatorenGemonteerd}
                                    status={pi.kopisolatorenGeplaatst}/>
                                <ProductionItemStationStatus
                                    title={ProductionItemOperations.ManchettesGemonteerd}
                                    status={pi.manchettesGeplaatst}/>
                                <ProductionItemStationStatus
                                    title={ProductionItemOperations.HefschuifVouwwand}
                                    status={pi.hefschuifVouwwandGereed}/>
                                <ProductionItemStationStatus
                                    title={ProductionItemOperations.Ingepakt}
                                    status={pi.ingepakt}/>
                                <ProductionItemStationStatus
                                    title={ProductionItemOperations.Custom}
                                    status={pi.special}/>
                            </div>
                        </td>
                        <td className='text-dark fw-bold fs-6 text-end'>
                        </td>
                    </tr>
                )}
                </tbody>
            </EkoTable>
        </>
    )
}

export {ProductionItemTable}
