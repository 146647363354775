import React, {useMemo, useRef, useState} from "react";
import {Formik, FormikConfig, FormikHelpers, FormikProps, FormikValues} from "formik";
import {Modal, ModalProps} from "react-bootstrap";
import {modalsRoot} from "../../global-helpers";


export type ModalFormFormikConfig<Values, ExtraProps> =
    Omit<FormikConfig<Values>, 'initialValues' | 'onSubmit'>;


export type UseModalFormConfig<Values extends FormikValues> = {
    onSubmit: (values: Values) => Promise<any>;
    formik?: ModalFormFormikConfig<Values, {}>;
    modal?: ModalProps;
}

export type ModalFormChildrenComponent = (props: ModalFormChildrenComponentProps) => React.ReactElement | React.ReactElement[];

export type ModalFormChildrenComponentProps = {
    close: () => void;
    isSubmitting: boolean;
    isValidating: boolean;
    isValid: boolean;
};

export const useModalForm = <Values extends FormikValues>(config: UseModalFormConfig<Values>) => {

    const [show, setShow] = useState(false);

    const values = useRef<Values | {}>({});

    const innerRef = useRef<FormikProps<Values> | null>(null);

    const open = (initialValues: Values) => {
        values.current = initialValues;
        innerRef.current?.resetForm();
        setShow(true);
    }
    const close = () => setShow(false);

    const onSubmit = async (values: Values, formikHelpers: FormikHelpers<Values>): Promise<any> => {
        await config.onSubmit(values);
        close();
    }

    const formikProps = useMemo(() => {

        const c: FormikConfig<Values> = {
            ...config.formik ?? {},
            innerRef: innerRef,
            initialValues: values.current! as Values,
            onSubmit: onSubmit
        }

        return c;

    }, [config.formik, values.current]);

    const modalProps = useMemo<ModalProps>(() => {

        const defaults: Partial<ModalProps> = {
            animation: false,
            size: 'lg',
        };

        return {
            ...defaults,
            ...config.modal,
            show: show,
            onHide: () => setShow(false)
        }
    }, [config.modal, show]);


    return {
        open: open,
        close: close,
        render: (renderChildren: ModalFormChildrenComponent) => {
            if (!show) return <></>;
            return <ModalForm formik={formikProps} modal={modalProps} children={renderChildren} onClose={close}/>
        }
    }
};


export type ModalFormProps<Values extends FormikValues> = {
    formik: FormikConfig<Values>;
    modal: ModalProps;
    onClose: () => void;
    children: ModalFormChildrenComponent;
};

export const ModalForm = <Values extends FormikValues, ExtraProps = {}>(props: ModalFormProps<Values>): React.ReactElement => {

    return <Formik {...props.formik}>
        {(formik) =>
            <Modal {...props.modal} container={modalsRoot}>
                {props.children({
                    close: props.onClose,
                    isSubmitting: formik.isSubmitting,
                    isValidating: formik.isValidating,
                    isValid: formik.isValid,
                })}
            </Modal>
        }
    </Formik>
}

