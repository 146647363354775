import React, {useState, useEffect} from "react";
import {
    ExpeditionForProductionItemRepresentation,
    ExpeditionsForProductionItemRow
} from "../../../../../modules/api-client/generated";
import {EkoTable} from "../../../../../shared/components/table";
import classNames from "classnames";
import {NumericFormat} from "react-number-format";
import {DateTime} from "luxon";
import {useQuery} from "@tanstack/react-query";
import ApiClient from "../../../../../modules/api-client/ApiClient";
import Loading from "../../../../../shared/components/Loading";

interface OrdersWithSillSummaryOverviewProps {
    showHeaderLine: boolean;
    showCheckboxes: boolean;
    orderIdToQuery: string;
    updateExpeditionStatusToDelivered: (orderIds: string[]) => void;
    orderIdsToShow: string[];
    customer: string;
}

const OrdersWithSillSummaryOverview: React.FC<OrdersWithSillSummaryOverviewProps> = (props) => {
    const [checkedOrderCount, setCheckedOrderCount] = useState<number>(0);
    const [checkedOrders, setCheckedOrders] = useState<{[key: string]: boolean }>({});

    const {
        isInitialLoading,
        data: expeditionData,
        isError
    } = useQuery<ExpeditionForProductionItemRepresentation>(['emExpeditionOrderIdGet', props.orderIdToQuery], () =>
        ApiClient.Em.Expedition.emExpeditionOrderIdGet(props.orderIdToQuery).then((res) => res.data));


    useEffect(() => {
        if (expeditionData && expeditionData.relatedExpeditions) {
            setCheckedOrders(initializeCheckedOrders(expeditionData.relatedExpeditions));
        }
    }, [expeditionData]);

    const btnText = checkedOrderCount === 1 ? '1 Order Afleveren' : `${checkedOrderCount} Orders Afleveren`;

    const initializeCheckedOrders = (ordersByExpeditionDate: any) => {
        const initialCheckedOrders: { [key: string]: boolean } = {};
        let count = 0;
        Object.values(ordersByExpeditionDate).forEach((datum: any) => {
            datum.expeditions.forEach((order: ExpeditionsForProductionItemRow) => {
                const isInTransit = order.expeditionStatus === "inTransit";
                initialCheckedOrders[order.orderId!] = isInTransit;
                if (isInTransit) {
                    count++;
                }
            });
        });
        setCheckedOrderCount(count);
        return initialCheckedOrders;
    };


    const onDeliverBtnClick = async () => {
        props.updateExpeditionStatusToDelivered(getSelectedOrders());
    }

    const getSelectedOrders = () => {
        return Object.keys(checkedOrders).filter((key) => checkedOrders[key]);
    }

    const hasExpeditionsToShow = (expeditions: ExpeditionsForProductionItemRow[]) => {
        return expeditions.filter(expedition => displayExpedition(expedition)).length > 0;
    }

    const displayExpedition = (expedition: ExpeditionsForProductionItemRow) =>
        (expedition.expeditionStatus === "inTransit" || expedition.expeditionStatus === 'delivered' || DateTime.fromISO(expedition.plannedExpeditionDate) <= DateTime.now());

    function getSillCountOfSelectedOrders() {
        const selectedOrders = Object.keys(checkedOrders).filter(key => checkedOrders[key]);
        return Object.values(expeditionData?.relatedExpeditions || {}).reduce((count, { expeditions }) =>
            count + expeditions.filter(order => selectedOrders.includes(order.orderId!)).reduce((sum, order) => sum + order.itemCount, 0), 0);
    }
    function getLongestSillLengthOfSelectedOrders() {
        const selectedOrders = Object.keys(checkedOrders).filter(key => checkedOrders[key]);
        return Object.values(expeditionData?.relatedExpeditions || {}).reduce((longest, { expeditions }) =>
            Math.max(longest, ...expeditions.filter(order => selectedOrders.includes(order.orderId!)).map(order => order.maxSillLengthOrder)), 0);
    }

    const handleCheckboxChange = (orderId: string) => {
        setCheckedOrderCount(prevCount => prevCount + (checkedOrders[orderId] ? -1 : 1));
        setCheckedOrders(prevState => ({
            ...prevState,
            [orderId]: !prevState[orderId]
        }));
    };

    if(isInitialLoading || !expeditionData) {
        return <Loading/>;
    }

    if(isError) {
        return <div>Error...</div>;
    }

    return (
        <>
            {props.showHeaderLine && (
                <EkoTable className={'card-xxl-stretch'} key="deliverHeader">
                    <colgroup>
                        {(props.showCheckboxes) && <col width={'5%'} />}
                        <col width={'40%'} />
                        <col width={'20%'} />
                        <col width={'10%'} />
                        <col width={'20%'} />
                    </colgroup>
                    <thead>
                    <tr>
                        <td></td>
                        <td>{props.customer}</td>
                        <td>
                            Langste dorpel:&nbsp;
                            <NumericFormat
                                className={'me-1 text-nowrap fs-4'} decimalScale={2}
                                value={getLongestSillLengthOfSelectedOrders() * 0.001}
                                displayType={'text'}
                                thousandSeparator={true} suffix={' m'}/>

                        </td>
                        <td>{getSillCountOfSelectedOrders()} dorpels</td>
                        <td>
                            <button className={`btn btn-success`}
                                    disabled={checkedOrderCount === 0}
                                    onClick={onDeliverBtnClick}>
                                {btnText}
                            </button>
                        </td>

                    </tr>
                    </thead>
                </EkoTable>
            )}
            {Object.values(expeditionData.relatedExpeditions).map((plannedDay) => {
                if(!hasExpeditionsToShow(plannedDay.expeditions)) {
                    return null;
                }
                return (
                    <React.Fragment key={plannedDay.plannedExpeditionDate}>
                        {plannedDay.expeditions.map((order) => {
                            if(props.orderIdsToShow.includes(order.orderId!)) {
                                return (
                                    <EkoTable className={'card-xxl-stretch'} key={order.orderId}>
                                        <colgroup>
                                            {(props.showCheckboxes) && <col width={'5%'}/>}
                                            <col width={'15%'}/>
                                            <col width={'25%'}/>
                                            <col width={'20%'}/>
                                            <col width={'10%'}/>
                                            <col width={'20%'}/>
                                        </colgroup>
                                        <tbody>
                                        <tr className={classNames((order.expeditionStatus !== 'inTransit' && order.expeditionStatus !== 'delivered') ? 'text-decoration-line-through text-muted' : '')}>
                                            {(props.showCheckboxes) && (
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        checked={checkedOrders[order.orderId]}
                                                        disabled={order.expeditionStatus !== 'inTransit'}
                                                        onChange={() => handleCheckboxChange(order.orderId!)}
                                                    />
                                                </td>
                                            )}
                                            <td>
                                                {order.orderCode}
                                            </td>
                                            <td>
                                                <span className='fs-6'>
                                                    {order.plannedExpeditionDate}
                                                </span>
                                            </td>
                                            <td>
                                                Langste dorpel:&nbsp;
                                                <NumericFormat
                                                    className={'me-1 text-nowrap fs-4'} decimalScale={2}
                                                    value={order.maxSillLengthOrder * 0.001}
                                                    displayType={'text'}
                                                    thousandSeparator={true} suffix={' m'}/>
                                            </td>
                                            <td>
                                                {order.itemCount} dorpels
                                            </td>
                                            <td className={'d-flex justify-content-end'}>
                                                {order.expeditionStatus === 'planned' &&
                                                    <button className={`btn btn-warning`} disabled={true}>
                                                        Order nog niet gereed
                                                    </button>
                                                }
                                                {order.expeditionStatus === 'readyForShipment' &&
                                                    <button className={`btn btn-info`}
                                                            disabled={true}>
                                                        Order onderweg melden
                                                    </button>
                                                }
                                            </td>
                                        </tr>
                                        </tbody>
                                    </EkoTable>
                                );
                            }
                            return <></>
                        })}
                    </React.Fragment>
                );
            })}
        </>
    );
}

export default OrdersWithSillSummaryOverview;