import React from 'react';
import { useQuery } from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import { Link, useParams } from 'react-router-dom';
import * as generated from '../../../../modules/api-client/generated';
import Loading from '../../../../shared/components/Loading';

const ContentDetails: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const { isInitialLoading, data: details, isError } = useQuery<generated.DownloadDetails>(['homepageDownloadDetailsGet'], () => ApiClient.Homepage.getDownloadDetails(id!).then((res) => res.data));

	if (isInitialLoading) {
		return <Loading />;
	}

	if (isError) {
		return <>ERROR!</>;
	}

	if (!details) return <></>;

	return (
		<div className='card mb-5'>
			<div className='card-header'>
				<h3 className='card-title'>Download {details.code}</h3>
				<div className='card-toolbar'>
					<>
						<Link to='edit' relative='path' className='btn btn-primary'>Wijzigen</Link>
						<a className="btn btn-primary  ms-2" href={details.download?.blobUri} download target="_blank">Download bestand</a>
					</>
				</div>
			</div>
			<div className='card-body position-relative'>
				<div className='row'>
					<div className='col-3'>
						<div style={{ maxHeight: '100%', maxWidth: '100%' }}>
							{details.image !== null && (
								<img
									src={details.image?.blobUri!}
									alt={details.image?.altText!}
									style={{ maxHeight: '100%', maxWidth: '100%', border: '2px solid #000', borderRadius: '8px' }}
								/>
							)}
						</div>
					</div>
					<div className='col'>
						<div className='row mb-4'>
							<div className='col-3'>
								<label className='form-label'>Code</label>
							</div>
							<div className='col'>{details.code}</div>
						</div>
						<div className='row mb-4'>
							<div className='col-3'>
								<label className='form-label'>Titel</label>
							</div>
							<div className='col'>
								{details.download?.title}
							</div>
						</div>
						<div className='row mb-4'>
							<div className='col-3'>
								<label className='form-label'>Omschrijving</label>
							</div>
							<div className='col'>
								{details.download?.description}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>)
};

export default ContentDetails;