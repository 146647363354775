import React, { useCallback, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import { Link } from 'react-router-dom';
import { EkoCard, EkoCardBody } from '../../../../../_metronic/helpers';
import { EkoCardHeader, EkoCardToolbar } from '../../../../shared/components/card';
import {ContentType, DownloadItem} from '../../../../modules/api-client/generated';
import Loading from '../../../../shared/components/Loading';
import Error from "../../../../shared/components/Error";
import { ConfirmDialog } from '../../../../shared/components/ConfirmDialog';
import { useContentDeleteMutation } from './hooks';

const ContentList: React.FC = () => {
    const {
        isInitialLoading,
        data: list,
        isError
    } = useQuery<DownloadItem[]>(['homepageContentListGet'], () => ApiClient.Homepage.getContentList(ContentType.Download).then((res) => res.data));

    if (isInitialLoading) return <Loading />;

    if (isError) return <Error />;
    
    return (
    <>
        <div className={'d-flex justify-content-between'}>
            <h4>Content</h4>
            <Link to={`../download/add`} rel="noreferrer" relative={"path"} className='btn btn-primary btn-sm'>Toevoegen</Link>
        </div>
        <div className={'row'}>
        {list?.map((data) => (
            <EkoCard className={'mt-3'} key={data.id}>
                <EkoCardHeader title={data.download?.title}>
                    <EkoCardToolbar>
                        <div className="fit-content">
                        <a className="btn btn-primary btn-sm me-1" href={data.download?.blobUri?.toString()} download target="_blank">Download</a>
                            <Link to={`../download/` + data.id!} relative='path' className={'btn btn-light btn-sm me-1'}><i
                                className={'fas fa-edit'}></i></Link>
                            <DeleteButton id={data.id!} />
                        </div>
                    </EkoCardToolbar>
                </EkoCardHeader>
                <EkoCardBody>
                    <div className={'row'}>
                        <div className={'col-3'}>
                        {data.thumbnail?.blobUri ? (
                            <img 
                                src={data.thumbnail.blobUri} 
                                alt={data.thumbnail?.altText ?? ''} 
                                style={{ border: '2px solid #000', borderRadius: '8px' }}
                            />
                            ) : (
                                <div 
                                    className="img-placeholder d-flex align-items-center justify-content-center rounded"
                                    style={{
                                        width: '60%',
                                        height: '150px',
                                        backgroundColor: '#f8f9fa',
                                        border: '2px dashed #ccc',
                                        color: '#6c757d',
                                        textAlign: 'center'
                                    }}
                                >
                                    Image processing...
                                </div>
                            )}
                        </div>
                        <div className={'col'}>
                            {data.download?.description}
                        </div>
                    </div>
                </EkoCardBody>
            </EkoCard>
        ))}
        </div>
    </>);
};

export default ContentList;

const DeleteButton: React.FC<{ id: string }> = ({ id }) => {
    const openDeleteDialog = useRef<(show: boolean) => void>();

    const deleteMutation = useContentDeleteMutation(id);
    const deleteCallback = useCallback(async () => {
        await deleteMutation.mutateAsync(id!);
    }, [deleteMutation, id]);
    return (
        <>
            <button type="button" className="btn btn-light btn-sm" disabled={false}
                    onClick={() => openDeleteDialog.current?.call(this, true)}><i className={'fas fa-trash'}></i>
            </button>

            <ConfirmDialog show={openDeleteDialog}
                onConfirm={deleteCallback}
                dialogStyle="danger"
                title="Verwijderen"
                message="Wil je doorgaan?"
                confirmText="Definitief verwijderen"
            />
        </>
    )
}