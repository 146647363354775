import React from "react";
import * as api from "../../../../modules/api-client/generated";

export interface ContentTypeTextProps {
    type: string | undefined;
}

export const ContentTypeText: React.FC<ContentTypeTextProps> = (props) => {

    switch (props.type) {
        case api.ContentType.ContentBlock.toLowerCase(): {
            return (<>Content blok</>);
        }
        case api.ContentType.Image.toLowerCase(): {
            return (<>Afbeelding</>);
        }
        case api.ContentType.NewsItem.toLowerCase(): {
            return (<>Nieuwsbericht</>);
        }
        case api.ContentType.Notification.toLowerCase(): {
            return (<>Notificatie</>);
        }
        case api.ContentType.Download.toLowerCase(): {
            return (<>Download</>);
        }         
    }

    return (<>{props.type}</>);
}