import React, {FC} from 'react'
import {EkoCard, EkoCardBody, KTSVG} from "../../../../../../../_metronic/helpers";

type Props = {
	url: string
	date: string
	username: string,
}

const ProofOfDeliveryOverview: FC<Props> = ({url, date, username}) => {

	return (
		<EkoCard className={'py-4 h-100'} flush={false}>
			<img src={url} alt="Afleverbewijs" style={{maxWidth: '100%', height: 'auto'}} />
			<div>datum: {date}</div>
			<div>gebruiker: {username}</div>
		</EkoCard>
	)
}

export {ProofOfDeliveryOverview}
