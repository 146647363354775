import {useQueryClient} from "@tanstack/react-query";
import {toast} from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import {    
    PutPlannedProductionDateRepresentation
} from "../../../../../modules/api-client/generated";
import {useEkosietMutation} from "../../../../../shared/hooks/useEkosietMutation";
import {NotFound, ValidationFailed} from "../../../../../modules/api-client/Responses";

export const usePutProductionDate = (id: string) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<void, NotFound | ValidationFailed, PutPlannedProductionDateRepresentation>(
        async (
            model: PutPlannedProductionDateRepresentation) => {
            await ApiClient.Sm.Order.smOrderIdProductionPlannedProductionDatePut(id, undefined, model);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['smOrderIdGet', id]);
                queryClient.invalidateQueries(['OrderActionLog', id]);

                queryClient.invalidateQueries(['smOrderListOdataGet']);
                toast.success("Productiedatum aangepast.");

            },
            onError: () => {
                toast.error("Productiedatum wijzigen mislukt!");
            }
        }
    );
};
